import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./index.css";
import Cards from "./FundManagersCard/Cards";
// import CardApi from "../API/FounderApi/CardApi";
import Axios from "axios";

const index = () => {
  const [nonverifiedManagers, setnonverifiedManagers] = useState();
  const fetchnonverifiedManagers = async () => {
    await Axios({
      method: "GET",
      url: "/api/admin/handle-fm",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        const { nonverified } = res.data;
        setnonverifiedManagers(nonverified);
      })
      .catch(() => console.log("error"));
  };

  useEffect(() => {
    fetchnonverifiedManagers();
  }, []);

  if (!nonverifiedManagers) {
    return "empty";
  }
  return (
    <>
      <div className="admin-root container">
        <div className="zr-nav">
          <NavLink to="/zr-admin-dashboard" className="p_small_grey2">
            Dashboard
          </NavLink>
          {/* &#47; */}
          <span>&#47;</span>
          <NavLink to="/admin/verify-founder" className="p_small_grey2">
            Pending Fund Manager Verifications
          </NavLink>
        </div>

        <div className="main-card-div">
          <div className="founderCard">
            {nonverifiedManagers
              ? nonverifiedManagers.map((manager) => (
                  <Cards key={manager.id} data={manager} />
                ))
              : "No Founders"}
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
