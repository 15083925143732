import "./App.css";
import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { ProvideAuth } from "./Utils/Auth";
import LogoNavbar from "./Layout/Navbar/logoNavbar";

import AdminLogin from "./Components/Admin/AdminLogin";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import NonVerifyFounders from "./Components/Admin/NonVerifyFounders";
import NonVerifyInvestors from "./Components/Admin/NonVerifyInvestors";
import NonverifyFounderPage from "./Components/Admin/NonVerifyFounderPage";
import NonverifyInvestorPage from "./Components/Admin/NonVerifyInvestorPage";
import NonverifyFundCompanies from "./Components/Admin/NonVerifyFundCompanies";
import NonverifyFundCompanyPage from "./Components/Admin/NonVerifyFundCompanyPage";
import NonverifyFundManagers from "./Components/Admin/NonVerifyFundManagers";
import NonverifyFundManagerPage from "./Components/Admin/NonVerifyFundManagerPage";
import Nonverifycorporate from "./Components/Admin/Corporate/NonVerifiedCorporates";
import NonverifycorporatePage from "./Components/Admin/Corporate/NonverifycorporatePage";
import Notifications from "./Components/Admin/Notifications";
import AdminCoporate from "./Components/Admin/Corporate";
import AdminCoporateRegister from "./Components/Admin/Corporate/Register";

import ValuationResponse from "./Components/Admin/ValuationResponses";
import ValuationResponsePage from "./Components/Admin/ValuationResponses/ReponsesPage";
import AdminAcademia from "./Components/Admin/Academia";
import LatestNews from "./Components/Admin/LatestNews";
import Platformupdates from "./Components/Admin/Platformupdates";
import UserActivity from "./Components/Admin/UserActivity";
import AdminPitcdeck from "./Components/Admin/Pitchdeck";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminContentLogin from "./Components/AdminContent";
import AdminContentDashboard from "./Components/AdminContent/dashboard";
import Allarticles from "./Components/AdminContent/Articles";
import UploadArticles from "./Components/AdminContent/UploadArticle";
import Sales from "./Components/Admin/SalesActivity";
import AllCorporates from "./Components/Admin/Corporate/AllCorporates";
import AllFunds from "./Components/Admin/Funds/AllFunds";
import AdminFunds from "./Components/Admin/Funds";
import AdminFundRegister from "./Components/Admin/Funds/Register";
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <ProvideAuth>
          <Routes>
            <Route exact path="/" element={<AdminLogin />} />
            <Route
              exact
              path="/zr-admin-dashboard"
              element={<AdminDashboard />}
            />
            <Route
              exact
              path="/admin/verify-founder"
              element={<NonVerifyFounders />}
            />

            <Route
              exact
              path="/admin/verify-investor"
              element={<NonVerifyInvestors />}
            />

            <Route
              exact
              path="/admin/verifyfounder/:founder"
              element={<NonverifyFounderPage />}
            />

            <Route
              exact
              path="/admin/verify-investor/:investor"
              element={<NonverifyInvestorPage />}
            />

            <Route
              exact
              path="/admin/verify-fundcompany"
              element={<NonverifyFundCompanies />}
            />

            <Route
              exact
              path="/admin/verify-fundmanager"
              element={<NonverifyFundManagers />}
            />

            <Route
              exact
              path="/admin/verify-fundcompany/:fundcompany"
              element={<NonverifyFundCompanyPage />}
            />

            <Route
              exact
              path="/admin/verify-fundmanager/:fundmanager"
              element={<NonverifyFundManagerPage />}
            />

            <Route
              exact
              path="/admin/verify-corporate"
              element={<Nonverifycorporate />}
            />

            <Route
              exact
              path="/admin/verify-corporate/:corporate"
              element={<NonverifycorporatePage />}
            />

            <Route
              exact
              path="/admin/notification"
              element={<Notifications />}
            />

            <Route exact path="/admin/pitchdeck" element={<AdminPitcdeck />} />

            <Route
              exact
              path="/admin/valuation"
              element={<ValuationResponse />}
            />

            <Route
              exact
              path="/admin/valuations/user"
              element={<ValuationResponsePage />}
            />

            {/* corporate admin --------------------------------- */}
            <Route exact path="/admin/corporate" element={<AdminCoporate />} />

            <Route
              exact
              path="/admin/corporate/register/:userid"
              element={<AdminCoporateRegister />}
            />
            <Route
              exact
              path="/admin/all-corporates"
              element={<AllCorporates />}
            />
            <Route exact path="/admin/all-funds" element={<AllFunds />} />

            <Route exact path="/admin/funds" element={<AdminFunds />} />
            <Route
              exact
              path="/admin/fund/register/:userid"
              element={<AdminFundRegister />}
            />
            <Route exact path="/admin/academia" element={<AdminAcademia />} />

            <Route exact path="/admin/latestNews" element={<LatestNews />} />
            <Route
              exact
              path="/admin/platformupdates"
              element={<Platformupdates />}
            />

            <Route
              exact
              path="/admin/user-activity"
              element={
                <>
                  <LogoNavbar />
                  <UserActivity />
                </>
              }
            />

            <Route
              exact
              path="/content-admin"
              element={
                <>
                  <LogoNavbar />
                  <AdminContentLogin />
                </>
              }
            />

            <Route
              exact
              path="/content-admindashboard"
              element={
                <>
                  {" "}
                  <LogoNavbar />
                  <AdminContentDashboard />
                </>
              }
            />

            <Route
              exact
              path="/article-upload"
              element={
                <>
                  {" "}
                  <LogoNavbar />
                  <UploadArticles />
                </>
              }
            />

            <Route
              exact
              path="/all-articles"
              element={
                <>
                  <LogoNavbar />
                  <Allarticles />
                </>
              }
            />

            {/* ----------Sales Routes -----------------*/}

            <Route
              exact
              path="/admin/sales"
              element={
                <>
                  <LogoNavbar />
                  <Sales />
                </>
              }
            />
          </Routes>
        </ProvideAuth>
      </Router>
    </div>
  );
}

export default App;
