import { Box, CircularProgress, IconButton, Modal } from "@mui/material";
import Axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Add, DriveFileRenameOutline } from "@mui/icons-material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  borderRadius: "10px",
  bgcolor: "background.paper",
  boxShadow: 24,

  p: 4,
};
export default function Invoices() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setisEdit({ isEdit: false, editId: null });
  };
  const [customer, setcustomer] = React.useState("");
  const [amount, setamount] = React.useState("");
  const [status, setstatus] = React.useState("Paid");
  const [date, setdate] = React.useState(new Date());
  const [invoiceUrl, setinvoiceUrl] = React.useState("");
  const [isUploading, setIsUploading] = React.useState(false);
  const [person, setperson] = useState("");
  const [fileName, setfileName] = useState("");
  const [email, setemail] = useState("");
  const [allInvoices, setallInvoices] = useState(null);
  const [isEdit, setisEdit] = useState({ isEdit: false, editId: null });
  const [totalRevenue, settotalRevenue] = useState(0);
  const [period, setperiod] = useState("all");
  const [startDate, setstartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setendDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const previousMonths = Array.from({ length: new Date().getMonth() }, (_, i) =>
    new Date(0, i).toLocaleString("default", { month: "long" })
  );
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const handleDocumentsChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file.type !== "application/pdf") {
      toast.error("PDF files are allowed!");
      return;
    }
    if (file.size > 2 * 10024 * 1024) {
      // Toast({ msg: "File size exceeded 2MB!", code: 0 });
      toast.error("File size exceeded 20MB!");
      return;
    }

    const formdata = new FormData();
    formdata.append("pdf", file);

    setIsUploading(true);
    await Axios({
      method: "post",
      url: `http://localhost:5002/api/admin/upload/invoice`,
      data: formdata,
    })
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            setcustomer(res.data.data.customer_name);
            setamount(res.data.data.total_amount);
            console.log(moment(res.data.data.date).format("YYYY-MM-DD"));
            setdate(moment(res.data.data.date).format("YYYY-MM-DD"));
            setinvoiceUrl(res.data.invoice);
            setfileName(res.data.fileName);
          }
          toast.success("uploaded");
          setIsUploading(false);
        }
      })
      .catch(() => {
        setIsUploading(false);
      });
  };
  const getInvoices = async () => {
    try {
      let res = await Axios.get(
        `http://localhost:5002/api/admin/invoices?period=${period}&startDate=${startDate}&endDate=${endDate}`
      );
      if (res.data.success) {
        setallInvoices(res.data.invoices);
        let s_rev = 0;
        res.data.invoices.map((rev) => (s_rev = rev.amount + s_rev));
        settotalRevenue(s_rev);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const saveInvoice = async () => {
    if (invoiceUrl === "" || date === "" || amount === "" || customer === "") {
      return toast.error("Incomplete Data");
    }
    try {
      let res = await Axios.post(
        `http://localhost:5002/api/admin/submit/invoice`,
        {
          invoice: invoiceUrl,
          date: date,
          customer: customer,
          amount: amount,
          status: status,
          fileName: fileName,
          person: person,
          email: email,
          isEdit: isEdit,
        }
      );
      if (res.data.success) {
        handleClose();
        setinvoiceUrl("");
        setdate("");
        setcustomer("");
        setamount("");
        setstatus("");
        setperson("");
        setfileName("");
        setemail("");
        setisEdit({ isEdit: false, editId: null });
        getInvoices();
        toast.success("Invoice Submitted");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    getInvoices();
  }, [period, startDate, endDate]);

  return (
    <div>
      <div className="d-flex align_center">
        <button className="gradient_admin_btn" onClick={() => handleOpen()}>
          Create Invoice <Add />
        </button>{" "}
        &nbsp; &nbsp;
        <button className="gradient_admin_btn" onClick={() => handleOpen()}>
          Upload Offline Invoice
        </button>{" "}
        &nbsp; &nbsp;
        <h5>
          Total Revenue:
          <span style={{ color: "green" }}>
            {" "}
            EUR{" "}
            {totalRevenue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </h5>
        <div
          className="ms-auto"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label>Period</label>
          <select
            className="auth_input"
            value={period}
            onChange={(e) => setperiod(e.target.value)}
          >
            <option value="all">All</option>
            <option>
              {monthNames[new Date().getMonth()]} {new Date().getFullYear()}
            </option>
            {previousMonths &&
              previousMonths
                .slice(-3)
                .reverse()
                .map((mon, i) => (
                  <option value={mon} key={i}>
                    {mon} {new Date().getFullYear()}
                  </option>
                ))}
            <option value="custom">Custom</option>
          </select>
        </div>
        {period === "custom" && (
          <div className="ms-4">
            <label>Custom Period</label>
            <div className="d-flex">
              <input
                type="date"
                className="auth_input "
                value={startDate}
                onChange={(e) => setstartDate(e.target.value)}
              />
              <input
                type="date"
                className="auth_input ms-2"
                value={endDate}
                min={startDate}
                onChange={(e) => setendDate(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
      <table style={{ width: "100%" }} className="mt-5 plan_table">
        <thead className="plan_thead">
          <tr className="plan_thead_tr plan_tr">
            <th className="plan_thead_tr plan_td">Name</th>
            <th className="plan_thead_tr plan_td">Status</th>
            <th className="plan_thead_tr plan_td">Company</th>
            <th className="plan_thead_tr plan_td">Amount(EUR)</th>
            <th className="plan_thead_tr plan_td">Contact Person</th>
            <th className="plan_thead_tr plan_td">Email</th>
            <th className="plan_thead_tr plan_td">ID</th>
            <th className="plan_thead_tr plan_td">Date</th>
            <th
              className="plan_thead_tr plan_td"
              style={{ width: "20px" }}
            ></th>
          </tr>
        </thead>
        <tbody className="plan_tbody">
          {allInvoices &&
            allInvoices.map((obj, i) => (
              <tr key={i} className="plan_tbody_tr plan_tr">
                <td className="plan_tbody_td plan_td">
                  <a href={obj.invoice} target="_blank" rel="noreferrer">
                    {obj.fileName}
                  </a>
                </td>
                <td className="plan_tbody_td plan_td">{obj.status}</td>
                <td className="plan_tbody_td plan_td">{obj.customer}</td>
                <td className="plan_tbody_td plan_td">
                  {obj.amount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="plan_tbody_td plan_td">{obj.person}</td>
                <td className="plan_tbody_td plan_td">{obj.email}</td>
                <td className="plan_tbody_td plan_td">{obj.userId}</td>
                <td className="plan_tbody_td plan_td">{obj.date}</td>
                <td
                  onClick={() => {
                    setcustomer(obj.customer);
                    setstatus(obj.status);
                    setemail(obj.email);
                    setdate(moment(obj.date).format("YYYY-MM-DD"));
                    setamount(obj.amount);
                    setperson(obj.person);
                    setinvoiceUrl(obj.invoice);
                    setisEdit({ isEdit: true, editId: obj._id });
                    setfileName(obj.fileName);
                    handleOpen();
                  }}
                >
                  <IconButton>
                    <DriveFileRenameOutline />
                  </IconButton>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="bold">Upload Offline Invoice</p>
          <label htmlFor="photoid" className="investor-file py-2 mb-2">
            Choose file
            <input
              type="file"
              accept="application/pdf image/*"
              name="photoid"
              id="photoid"
              hidden
              onChange={(e) => handleDocumentsChange(e)}
            />{" "}
          </label>
          &nbsp; &nbsp; &nbsp;
          {isUploading ? <CircularProgress /> : null}
          {invoiceUrl && invoiceUrl !== "" && (
            <a
              href={invoiceUrl}
              target="_blank"
              rel="noreferrer"
              className="black"
            >
              File
            </a>
          )}
          <div className="d-flex flex-wrap">
            <input
              type="text"
              placeholder="Customer"
              className="w-40 auth_input"
              value={customer}
              onChange={(e) => setcustomer(e.target.value)}
            />
            <input
              type="int"
              placeholder="Amount"
              className="w-40 auth_input ms-4"
              value={amount}
              onChange={(e) => setamount(e.target.value)}
            />
            <input
              type="date"
              placeholder="Date"
              className="w-40 auth_input"
              value={date}
              onChange={(e) => setdate(e.target.value)}
            />
            <select
              type="text"
              placeholder="Status"
              className="w-40 auth_input ms-4"
              value={status}
              setstatus={(e) => setstatus(e.target.value)}
            >
              <option value={"paid"}>Paid</option>
              <option value={"pending"}>Pending</option>
              <option value={"declined"}>Declined</option>
            </select>
            <input
              type="text"
              placeholder="Contact Person"
              className="w-40 auth_input"
              value={person}
              onChange={(e) => setperson(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              className="w-40 auth_input  ms-4"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
            <input
              type="text"
              placeholder="File Name"
              className="w-40 auth_input"
              value={fileName}
              onChange={(e) => setfileName(e.target.value)}
            />
          </div>
          <button
            className="gradient_admin_btn mt-3"
            onClick={() => saveInvoice()}
          >
            {isEdit.isEdit ? "Edit Invoice" : "Add Invoice"}
          </button>
        </Box>
      </Modal>
    </div>
  );
}
