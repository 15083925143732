import React from "react";
import { useNavigate } from "react-router-dom";

export default function VReportCards({ valuation }) {
  const history = useNavigate();

  const getValuationResponse = () => {
    history(`/admin/valuations/user?user=${valuation._id}`);
  };

  return (
    <div className="col-3">
      <div
        style={{
          padding: "30px",
          borderRadius: "5px",
          border: "1px solid #fff",
          cursor: "pointer",
        }}
        onClick={getValuationResponse}
      >
        <img
          src={valuation.user.avatar}
          alt="report"
          className="img-responsive"
          width="100px"
        />
        <h4 className="p_small_white">{valuation.user.name}</h4>
        <div>
          <h5 className="p_small_white">{valuation.reportTitle} &nbsp;</h5>
          <button
            className={
              !valuation.reportGenerated ? "btn btn-danger" : "btn btn-success"
            }
            disabled
          >
            {!valuation.reportGenerated ? "Pending" : "Done"}
          </button>
        </div>
      </div>
    </div>
  );
}
