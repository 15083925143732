import React from "react";

const UpIntradayIcon = ({ cc, large }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={large ? "30" : "20"}
      height={large ? "30" : "12"}
      fill="none"
      viewBox="0 0 16 10"
    >
      <path
        fill={cc || "#E35700"}
        d="M13.126 1.627c-.185 0-.37.07-.512.213L8.596 5.858 6.25 3.509a.723.723 0 00-1.023 0L.572 8.163a.723.723 0 101.021 1.024l4.145-4.145 2.346 2.349a.724.724 0 001.024 0l4.528-4.53a.722.722 0 00-.51-1.234z"
      ></path>
      <path
        fill={cc || "#E35700"}
        fillRule="evenodd"
        d="M1.416 9.01a.472.472 0 11-.667-.67l4.654-4.654a.473.473 0 01.669 0L8.596 6.21l4.194-4.194a.47.47 0 01.668-.001l.002.001a.472.472 0 010 .668L8.93 7.215a.474.474 0 01-.67-.001L5.738 4.689 1.416 9.01zm-1.021.354c.38.38.995.38 1.375 0l3.968-3.968 2.17 2.172c.38.379.997.38 1.377 0l4.528-4.53a.972.972 0 10-1.376-1.374l-3.84 3.84-2.172-2.172a.974.974 0 00-1.376 0L.395 7.987a.973.973 0 000 1.377z"
        clipRule="evenodd"
      ></path>
      <path
        fill={cc || "#E35700"}
        d="M14.286.476H10.31a.724.724 0 000 1.446h3.253v3.284a.722.722 0 101.446 0V1.198a.723.723 0 00-.723-.722z"
      ></path>
      <path
        fill={cc || "#E35700"}
        fillRule="evenodd"
        d="M14.286 5.68a.472.472 0 01-.473-.474V1.672H10.31a.473.473 0 110-.946h3.976c.261 0 .473.212.473.472v4.008a.472.472 0 01-.473.474zm-.973-.474a.972.972 0 101.946 0V1.198a.973.973 0 00-.973-.972H10.31a.974.974 0 000 1.946h3.003v3.034z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default UpIntradayIcon;
