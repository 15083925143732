import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const Cards = ({ data }) => {
  // const { founder } = data;
  const history = useNavigate();
  const handleRedirect = (data) => {
    history(`/admin/verify-corporate/${data.legalname}`, { state: { data } });
  };

  // if (!data) {
  //   return "empty";
  // }

  return (
    <>
      <div className="logo-div">
        <div className="main-logo">
          <figure>
            <img src={data.logo} alt="bookify" className="img-fluid" />
          </figure>
          <figcaption>
            <p className="companyName">{data.nameOfCorporateType}</p>
            <p>({data.legalname})</p>
            <p className="founderName">{data.typeOfCorporate}</p>
            <p className="location">
              {data.location.country ? data.location.country : data.location}
            </p>
          </figcaption>
        </div>

        <div className="viewBtn">
          <button
            className="gradient_admin_btn mt-4"
            onClick={() => handleRedirect(data)}
          >
            View
          </button>
        </div>
      </div>
    </>
  );
};

Cards.propTypes = {
  data: PropTypes.any.isRequired,
};

export default Cards;
