import React, { useState } from "react";
import Axios from "axios";

import { Avatar, CircularProgress, Pagination } from "@mui/material";

export default function Activity() {
  const [activity, setactivity] = React.useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const fetchStats = async () => {
    const { data } = await Axios({
      method: "get",
      url: `/api/activity/connections?page=${page}&size=30`,
    });
    setactivity(data.data);
    setCount(data.count);
  };
  React.useEffect(() => {
    fetchStats();
  }, [page]);
  if (activity === null) {
    return <CircularProgress />;
  }

  return (
    <div className="container admin-user-activity">
      <h3>Connection Activity</h3>
      {activity ? (
        <>
          <table>
            <tr>
              <th>Sender</th>
              <th>Receiver</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
            {activity.length > 0 &&
              activity.map((res, i) => (
                <tr key={i} className="my-2 pt-2">
                  <td className=" my-2 pt-2">
                    <Avatar src={res.sender ? res.sender.avatar : ""} />
                    {res.sender ? `${res.sender.name} (${res.senderType})` : ""}
                  </td>
                  <td className=" my-2 pt-2">
                    <Avatar src={res.receiver ? res.receiver.avatar : ""} />
                    {res.receiver
                      ? `${res.receiver.name} (${res.receiverType})`
                      : ""}
                  </td>
                  {res.lastConnectedAt ? (
                    <td className=" pt-2">
                      Accepted at{" "}
                      {new Date(res.lastConnectedAt).toLocaleTimeString()}{" "}
                      {new Date(res.lastConnectedAt).toLocaleDateString()}
                    </td>
                  ) : res.lastRequestedAt ? (
                    <td className=" pt-2">
                      Requested at{" "}
                      {new Date(res.lastRequestedAt).toLocaleTimeString()}{" "}
                      {new Date(res.lastRequestedAt).toLocaleDateString()}
                    </td>
                  ) : (
                    <td className=" pt-2">
                      {new Date(res.updatedAt).toLocaleTimeString()}{" "}
                      {new Date(res.updatedAt).toLocaleDateString()}
                    </td>
                  )}

                  <td className=" my-2 pt-2">
                    {res.isAccepted ? (
                      <span style={{ color: "green" }}>Accepted</span>
                    ) : (
                      <span style={{ color: "red" }}>Pending</span>
                    )}
                  </td>
                </tr>
              ))}
          </table>
          <div className="pagination">
            <Pagination
              count={count ? Math.ceil(count / 20) : 0}
              page={page}
              onChange={(e, v) => setPage(v)}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
