import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import RoomIcon from "@mui/icons-material/Room";
// import PhoneIcon from "@mui/icons-material/Phone";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import LinkedIn from "@mui/icons-material/LinkedIn";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    width: "100%",
    margin: "5px 0",
    padding: "10px",
    "& > *": {
      margin: "3px 3px !important",
      color: "#4ad7d1 !important",
      border: "1px solid #4ad7d1 !important",
      padding: "7px !important",
    },
  },
}));

const index = () => {
  // const { investor } = useParams();
  const location = useLocation();
  const history = useNavigate();
  const { data } = location.state;
  const [verified, setVerify] = useState(false);
  //   const [declined, setDecline] = useState(false);
  const classes = useStyles();
  const [document, setdocument] = useState();
  const [review, setreview] = useState();

  // verify investr
  const handleVerify = async () => {
    if (!confirm("Are you sure you want to Verify this?")) {
      return;
    }
    await Axios.put(`/api/admin/verify-corporate/${data.id}`)
      .then(() => {
        toast.success("Successfully Verified");
        setVerify(true);
        history("/admin/verify-corporate");
      })
      .catch(() => {
        toast.error("Some Error");
        setVerify(false);
      });
  };

  // decline investor
  //   const handleDecline = async () => {
  //     if (!confirm("Are you sure you want to Decline this?")) {
  //       return;
  //     }
  //     await Axios.post("/api/admin/handle-investors/decline", data)
  //       .then(() => {
  //         toast.warning("Successfully Declined");
  //         setDecline(true);
  //         setTimeout(() => {
  //           history("/admin/verify-investor");
  //         }, 500);
  //       })
  //       .catch(() => {
  //         toast.error("Some Error");
  //         setDecline(false);
  //       });
  //   };

  const fetchDocument = async () => {
    await Axios({
      method: "GET",
      url: `/api/admin/documents/${data.id}`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        setdocument(res.data);
      })
      .catch(() => console.log("error"));
  };
  const sendReview = async () => {
    if (!confirm("Are you sure you want to Send this review?")) {
      return;
    }
    const data = {
      id: data.id,
      reason: review,
    };
    await Axios.post("/api/admin/review", data)
      .then(() => {
        toast.success("Successfully Sent Review");
      })
      .catch(() => {
        toast.error("some error");
      });
  };
  useEffect(() => {
    fetchDocument();
  }, []);

  if (!document) {
    return "empty";
  }
  if (!data) {
    return "Empty";
  }
  return (
    <>
      <div className="admin-root container">
        <div className="founder-verify-main">
          <div className="verify-founder-head">
            <div className="">
              {/* <figure> */}
              <Avatar
                src={data.logo}
                alt="logo"
                //   className="img-fluid"
                sx={{ width: 90, height: 90 }}
              />
              {/* </figure> */}
              <figcaption>
                <div className="">
                  <p className="verify-investor-name">{data.legalname}</p>
                  <p>Corporate Type : {data.typeOfCorporate}</p>
                  <p className="verify-investor-name">
                    {data.nameOfCorporateType}
                  </p>
                </div>
              </figcaption>
            </div>
            <div className="verify-founder-entities">
              <div className="entityName">
                <h3>Company Size Plan: {data.companySize}</h3>
              </div>
              <p>Field of Interest: {data.fieldOfInterest}</p>
              <div className="intrestedin">
                <p>Interested in</p>
                <div className={classes.root}>
                  {data.interestedIndustry.map((interest, i) => {
                    return (
                      <Chip
                        label={interest}
                        clickable
                        color="primary"
                        variant="outlined"
                        key={i}
                      />
                    );
                  })}
                </div>
              </div>
              <a href={data.links} target="_blank" rel="noopener noreferrer">
                <LinkedIn /> LinkedIn
              </a>
              <a href={data.document} target="_blank" rel="noopener noreferrer">
                Document
              </a>
            </div>
          </div>
          <hr className="verify-founder-hr" />
          <div className="verify-investor-main-details">
            <div className="all-main-details">
              <div className="allinfo-details-item">
                <RoomIcon />
                <p>{data.location.country}</p>
              </div>
              <div
                className="verify-founder-review"
                style={{ display: "none" }}
              >
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setreview(e.target.value)}
                  value={review}
                  className="reviewInput"
                  placeholder="Send review to the Investor"
                />
                <div>
                  <button className="black_CTA_view">ADD REVIEW</button>
                  <button
                    onClick={() => sendReview()}
                    className="gradient_admin_btn"
                  >
                    SEND ALL REVIEW
                  </button>
                </div>
              </div>
            </div>
            <div className="verify-investor-photoId">
              {/*
              <div className="verify-investor-imgicon">
                <ImageIcon />
                <p>Photo Id</p>
              </div>
              <figure>
                <img
                  src={investor.photoId}
                  alt={investor.fullname}
                  className="img-fluid"
                />
              </figure> */}

              <div className="verifybtn">
                {/* {declined ? (
                  <button className="black_CTA_view">DECLINED</button>
                ) : (
                  <button
                    className="black_CTA_view"
                    onClick={() => handleDecline()}
                  >
                    DECLINE
                  </button>
                )} */}

                {verified ? (
                  <button className="gradient_admin_btn">
                    <DoneAllIcon />
                    VERIFIED
                  </button>
                ) : (
                  <button
                    className="gradient_admin_btn"
                    onClick={() => handleVerify()}
                  >
                    VERIFY
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default index;
