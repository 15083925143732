import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./index.css";
import { CircularProgress } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { toast, ToastContainer } from "react-toastify";
import { Avatar } from "@mui/material";
import { Box, Modal, Pagination } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  width: "568px",
  borderRadius: "24px",
  bgcolor: "white",
  boxShadow: "2px 2px 8px rgba(83, 83, 83, 0.12)",
  padding: "32px",
};
const BullJobLogs = () => {
  const [plans, setPlans] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [plantype, setplantype] = useState("All");
  const [status, setstatus] = useState("All");
  const [newplantype, setnewplantype] = useState("");
  const [newplanduration, setnewplanduration] = useState("");
  const [sendPlanUpdatedEmail, setsendPlanUpdatedEmail] = useState(true);
  // const [sendReminders, setsendReminders] = useState(true);

  const [sorttype, setsorttype] = useState("recentexpire");
  const [loader, setloader] = useState(false);

  const [startDate, setStartDate] = useState(new Date("Sun Jan 01 2022"));
  const [endDate, setEndDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  );
  const [searchuser, setsearchuser] = useState("");
  const [fetchedusers, setfetchedusers] = useState([]);
  const [selectedUserId, setselectedUserId] = useState(null);
  const [currentuser, setcurrentuser] = useState(null);
  const fetchPlans = async () => {
    setloader(true);
    await Axios({
      method: "get",
      url: `/api/cronjobs/alljobs?status=${status}&type=${plantype}&start=${startDate}&end=${endDate}&userid=${selectedUserId?._id}&sorttype=${sorttype}&page=${page}&size=20`,
    })
      .then((res) => {
        setPlans(res.data.data);
        setloader(false);
        setCount(res.data.count);
        setsearchuser("");
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };
  const fetchUserByName = async () => {
    try {
      // if (searchuser.trim() === "") {
      //   setselectedUserId(null);
      //   setfetchedusers([]);
      //   return;
      // }
      const response = await Axios.get(`/api/admin/searchuser?s=${searchuser}`);
      if (response.data.success) {
        setfetchedusers(response.data.data);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };
  const handleUpdatePlan = async () => {
    try {
      if (!confirm("Are you sure of updating this user's plan?")) return;
      const response = await Axios.post(`/api/admin/updateplan`, {
        newplantype,
        userid: currentuser?._id,
        newplanduration,
        sendPlanUpdatedEmail,
      });
      if (response.data.success) {
        handleclose();
        toast.success("Plan updated successfully");
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };

  useEffect(() => {
    fetchPlans();
  }, [status, plantype, startDate, endDate, selectedUserId, sorttype, page]);
  useEffect(() => {
    if (searchuser?.trim() != "") fetchUserByName();
    else setfetchedusers([]);
  }, [searchuser]);

  const returnDate = (d) => {
    return new Date(d).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };
  const [open, setopen] = useState(false);
  const handleopen = (u) => {
    setcurrentuser(u);
    setopen(false);
  };
  const handleclose = () => setopen(false);

  return (
    <>
      <ToastContainer />
      <div className="plan_root">
        <div className="d-flex align-items-center justify-content-between">
          <div className="">
            <div>
              <p>Search User</p>
              <input
                style={{ width: "500px", padding: "8px" }}
                value={searchuser}
                onChange={(e) => setsearchuser(e.target.value)}
                type="text"
                className="h-40px"
                placeholder="Get timeline by name"
              />
            </div>
            <div className="gd_explore_main_results_block">
              <div
                style={{ backgroundColor: "white" }}
                className="gd_explore_main_results"
              >
                {fetchedusers.map((v, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      setselectedUserId(v);
                      setfetchedusers([]);
                    }}
                    className="gd_explore_main_result d-flex justify-content-between align-items-center mb-16 pointer"
                  >
                    <div className="d-flex align-items-center">
                      <Avatar
                        src={v.avatar}
                        alt={v.name ?? v.firstname}
                        style={{ height: 40, width: 40 }}
                      />
                      <div style={{ color: "black" }} className="ms-2">
                        <p className="m-0">
                          {v.type === "member"
                            ? `${v.firstname} ${v.lastname},(${v.type}) `
                            : `${v.name}, (${v.firstname} ${v.lastname},${v.type})`}

                          <span className="caption"> {v.email}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="">
            <p className="" htmlFor="typeDropdown">
              Select status
            </p>
            <select
              id="typeDropdown"
              className="h-40px"
              value={status}
              onChange={(e) => setstatus(e.target.value)}
            >
              <option value="All">All</option>
              <option value="delayed">Pending</option>
              <option value="stopped">Completed</option>
              <option value="failed">Failed</option>
            </select>
          </div>
          <div className="">
            <p className="" htmlFor="typeDropdown">
              Select Job Type
            </p>
            <select
              id="typeDropdown"
              value={plantype}
              className="h-40px"
              onChange={(e) => setplantype(e.target.value)}
            >
              <option value="All">All</option>
              <option value="profilereminder">Profile Jobs</option>
              <option value="plan">Plan Jobs</option>
              <option value="event">Event Jobs</option>
              <option value="connection-reminder">Connection updates</option>
              <option value="zefyron-updates">Zefyron updates</option>
              <option value="zefyron-feedback">Zefyron feedback</option>
            </select>
          </div>
          <div className="">
            <p htmlFor="typeDropdown">Sort</p>
            <select
              id="typeDropdown"
              value={sorttype}
              className="h-40px"
              onChange={(e) => setsorttype(e.target.value)}
            >
              <option value="recentexpire">Recent Jobs</option>
              <option value="increaseexpiry"> Ascending Schedule</option>
              <option value="decreaseexpiry">Descending Schedule</option>
            </select>
          </div>
          <div className="">
            <p> Timeline</p>

            <div style={{ backgroundColor: "white", width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  style={{ backgroundColor: "white" }}
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    width: "170px",
                    height: "40px",
                  }}
                  format="dd MMM yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Start Date"
                    />
                  )}
                />
              </LocalizationProvider>
              &nbsp;
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    width: "170px",
                    height: "40px",
                  }}
                  format="dd MMM yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="End Date"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        {loader && <CircularProgress />}
        <div className="plan_main">
          {selectedUserId ? (
            <>
              <p
                className="row align-items-center mx-0 mb-0 pointer"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  padding: "8px",
                  width: "50%",
                }}
              >
                <Avatar src={selectedUserId.avatar} />
                &nbsp;
                {`${selectedUserId.firstname} ${selectedUserId.lastname}, (${selectedUserId.name},${selectedUserId.type}) `}
              </p>
              <button
                onClick={() => setselectedUserId(null)}
                style={{ backgroundColor: "red" }}
              >
                X
              </button>
            </>
          ) : (
            ""
          )}
          {plans.length > 0 ? (
            <table className="plan_table mt-24">
              <thead className="plan_thead">
                <tr className="plan_thead_tr plan_tr">
                  <th className="plan_thead_tr plan_td">Job Name</th>
                  <th className="plan_thead_tr plan_td">Job Schedule</th>
                  <th className="plan_thead_tr plan_td">Job Status</th>
                  <th className="plan_thead_tr plan_td">User</th>
                  <th className="plan_thead_tr plan_td">Type</th>
                  <th className="plan_thead_tr plan_td">Profile Status</th>
                </tr>
              </thead>
              <tbody className="plan_tbody">
                {plans.map((plan) => (
                  <tr key={plan._id} className="plan_tbody_tr plan_tr">
                    <td
                      onClick={() => handleopen()}
                      className="plan_tbody_td plan_td"
                    >
                      {plan.name}
                    </td>
                    <td
                      style={
                        plan.status === "stopped"
                          ? { color: "green" }
                          : plan.status === "delayed"
                          ? { color: "skyblue" }
                          : { color: "red" }
                      }
                      className="plan_tbody_td plan_td"
                    >
                      {plan.type === "repeating" ? (
                        <>
                          <ul>Last run at {returnDate(plan.lastRunAt)}</ul>
                          <ul style={{ color: "green" }}>
                            Next run at{" "}
                            {returnDate(
                              plan.name === "zefyron-updates"
                                ? new Date(plan.lastRunAt).setDate(
                                    new Date(plan.lastRunAt).getDate() + 14
                                  )
                                : new Date(plan.nextRunAt)
                            )}
                          </ul>
                        </>
                      ) : (
                        <>
                          {plan.status === "stopped"
                            ? `Job ran success at ${returnDate(plan.startDate)}`
                            : plan.status === "delayed"
                            ? `Scheduled at  ${returnDate(plan.startDate)}`
                            : `Failed Job Scheduled at ${returnDate(
                                plan.startDate
                              )}`}
                        </>
                      )}
                    </td>

                    <td className="plan_tbody_td plan_td">{plan.status}</td>
                    <td className="plan_tbody_td plan_td">
                      <div className="d-flex plan_tbody_logo align-items-center">
                        {plan.user ? (
                          <>
                            <img src={plan?.user?.avatar} alt="logo" />
                            <div>
                              <p className="m-0">
                                {plan?.user?.name
                                  ? plan?.user?.name
                                  : `${plan?.user?.firstname} ${plan?.user?.lastname}`}
                              </p>
                              <p className="m-0 mt-8">{`${plan?.user?.email}`}</p>
                            </div>
                          </>
                        ) : plan.event ? (
                          <>
                            <img src={plan?.event?.thumbnail} alt="logo" />
                            <div>
                              <p className="m-0">
                                {plan?.event?.title} on{" "}
                                {returnDate(plan.event?.eventUTCtime)}
                              </p>
                              <p className="m-0 mt-8">{`${plan?.event?.hostCompany}`}</p>
                            </div>
                          </>
                        ) : (
                          "Not Found"
                        )}
                      </div>
                    </td>
                    <td className="plan_tbody_td plan_td">
                      {plan?.user?.type}
                    </td>
                    <td className="plan_tbody_td plan_td">
                      {(plan.user?.type === "member" &&
                        plan.user?.isEmailVerified) ||
                      plan?.user?.isProfileVerified
                        ? "Verified"
                        : "Not Verified"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h2>No Jobs</h2>
          )}
        </div>
        <div className="pagination">
          <Pagination
            count={count ? Math.ceil(count / 20) : 0}
            page={page}
            onChange={(e, v) => setPage(v)}
          />
        </div>
      </div>
      <Modal
        className=" "
        open={open}
        onClose={handleclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {currentuser ? (
            <>
              <p className="bold">
                {`${currentuser.name}, ( ${currentuser.firstname} ${currentuser.lastname},${currentuser.type})`}
              </p>
              <p>
                {`Current Plan: ${currentuser.plan?.planName} ${
                  currentuser.plan?.planName !== "Free"
                    ? `, with expiry date ${returnDate(
                        currentuser.plan?.endDate
                      )}`
                    : ""
                } `}
              </p>
              <div>
                <label className="mb-3" htmlFor="typeDropdown">
                  Update Plan
                </label>
                <select
                  id="typeDropdown"
                  value={newplantype}
                  onChange={(e) => setnewplantype(e.target.value)}
                >
                  <option value="">Select New Plan</option>
                  <option value="Free">Free</option>
                  <option value="Freemium">Freemium</option>
                  <option
                    hidden={["corporate", "institute", "member"].includes(
                      currentuser.type
                    )}
                    value="Basic"
                  >
                    Basic
                  </option>
                  <option value="Premium">Premium</option>
                </select>
              </div>
              <div>
                <label className="mb-3" htmlFor="typeDropdown">
                  Plan Duration
                </label>
                <select
                  id="typeDropdown"
                  value={newplanduration}
                  onChange={(e) => setnewplanduration(e.target.value)}
                >
                  <option value="">Extend Plan Duration to</option>
                  <option value="1w">1 Week</option>
                  <option value="1m">1 Month</option>
                  <option value="3m">3 Months</option>
                  <option value="6m">6 Months</option>
                  <option value="1y">1 Year</option>
                </select>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={sendPlanUpdatedEmail}
                    onChange={(e) => setsendPlanUpdatedEmail(e.target.checked)}
                  />
                  Send Plan Updated Email
                </label>
              </div>
              <br />
              <div>
                <button
                  onClick={() => handleUpdatePlan()}
                  className="gradient_admin_btn"
                >
                  Update Plan
                </button>
              </div>
              {/* <div>
                <label>
                  <input
                    type="checkbox"
                    checked={sendReminders}
                    onChange={(e) => setsendReminders(e.target.checked)}
                  />
                  Send Email on Expiry and Reminders
                </label>
              </div> */}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default BullJobLogs;
