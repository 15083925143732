/** @format */

import Axios from "axios";
import React, { useState } from "react";
import useMediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { ToastContainer, toast } from "react-toastify";
import generatePassword from "../../../Utils/GeneratePassword";

export default function Signup() {
  const history = useNavigate();
  // const isNumberRegx = /\d/;\
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  //eslint-disable-next-line
  // const specialCharacterRegx ='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})';
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);
  const [isExistMail, setIsExistMail] = useState(false);

  const handleEmailChange = (email) => {
    setEmail(email);
    const data = { email };
    Axios.post("/api/admin/check_email_exist", data).then((res) => {
      if (!res.data.isExist) {
        setIsExistMail(false);
      } else {
        setIsExistMail(true);
      }
    });
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const txt = e.target.value;
    if (
      // txt.length >= 8 &&
      // isNumberRegx.test(txt) &&
      regex.test(txt)
    ) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!validPassword) {
      toast.error("Password is not valid", { position: "bottom-right" });
      return;
    }
    const data = {
      firstname: firstName,
      lastname: lastName,
      email,
      password,
      type: "fundcompany",
    };
    await Axios.post("/api/fund/admin-signup", data)
      .then((res) => {
        if (res.data.success === true) {
          toast.success("Signup Complete", { position: "bottom-right" });
          history(`/admin/fund/register/${res.data.user._id}`);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, { position: "bottom-right" });
      });
  };

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });

  React.useEffect(() => {
    // var chars =
    //   "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    // var passwordLength = 10;
    // var passwordGen = "";

    // for (var i = 0; i <= passwordLength; i++) {
    //   var randomNumber = Math.floor(Math.random() * chars.length);
    //   passwordGen += chars.substring(randomNumber, randomNumber + 1);
    // }
    setPassword(generatePassword());
  }, [values.showPassword]);

  return (
    <>
      <br />
      <br />

      <p
        className="p_small_white p_button"
        onClick={() => history("/zr-admin-dashboard")}
      >
        {" "}
        &nbsp;&nbsp;&nbsp;&nbsp;Back to dashboard
      </p>
      <div className="auth_root">
        <ToastContainer />

        <div
          className="container auth_container"
          style={{ marginTop: !isMobile ? "2vh" : "5vh" }}
        >
          <div className="auth_header">
            <h1 className="h2_white_30">FUND COMPANY USER ONBOARD</h1>
          </div>
          <div className="auth_form">
            <form onSubmit={handleOnSubmit}>
              <div className="row no-margin justify-content-space-evenly">
                <div className="no-left-padding">
                  <input
                    id="First Name"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    type="text"
                    className="auth_input"
                    value={firstName}
                  />
                </div>
                <div className="no-left-padding">
                  <input
                    id="Last Name"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    type="text"
                    className="auth_input"
                    value={lastName}
                  />
                </div>
              </div>
              <input
                id="email"
                placeholder="Enter your email"
                onChange={(e) => handleEmailChange(e.target.value)}
                required
                type="email"
                className="auth_input"
                value={email}
              />
              <div style={{ textAlign: "left" }}>
                <p
                  className="p_small_white no-margin"
                  style={{ display: isExistMail ? "block" : "none" }}
                >
                  This email already exists
                </p>
              </div>
              <label className="auth_label">
                <input
                  className="auth_input auth_input_password"
                  placeholder="Enter your password"
                  required
                  type={!values.showPassword ? "text" : "text"}
                  value={password}
                  onChange={(e) => handlePasswordChange(e)}
                />
                <button
                  className="auth_input_pas_btn"
                  type="button"
                  onClick={() => handleClickShowPassword()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    className="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                  {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
                </button>
              </label>
              <div style={{ textAlign: "left" }}>
                <p
                  className="p_small_white no-margin"
                  style={{ display: validPassword ? "none" : "block" }}
                >
                  Password should include atleast an uppercase letter, a
                  lowercase letter,a number and a special character like
                  @,!,etc.
                </p>
              </div>
              {/* <br /> */}
              <div className="loginbtns">
                <button className="auth_CTA gradient_CTA" type="submit">
                  SIGN-UP
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
