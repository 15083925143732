import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import RoomIcon from "@mui/icons-material/Room";
// import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import ImageIcon from "@mui/icons-material/Image";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    width: "100%",
    margin: "5px 0",
    padding: "10px",
    "& > *": {
      margin: "3px 3px !important",
      color: "#4ad7d1 !important",
      border: "1px solid #4ad7d1 !important",
      padding: "7px !important",
    },
  },
}));

const index = () => {
  // const { investor } = useParams();
  const location = useLocation();
  const history = useNavigate();
  const { investor } = location.state;
  const [verified, setVerify] = useState(false);
  const [declined, setDecline] = useState(false);
  const classes = useStyles();
  const [document, setdocument] = useState();
  const [review, setreview] = useState();

  // verify investr
  const handleVerify = async () => {
    if (!confirm("Are you sure you want to Verify this?")) {
      return;
    }
    await Axios.post("/api/admin/handle-investors/accept", investor)
      .then(() => {
        toast.success("Successfully Verified");
        setVerify(true);
      })
      .catch(() => {
        toast.error("Some Error");
        setVerify(false);
      });
  };

  // decline investor
  const handleDecline = async () => {
    if (!confirm("Are you sure you want to Decline this?")) {
      return;
    }
    await Axios.post("/api/admin/handle-investors/decline", investor)
      .then(() => {
        toast.warning("Successfully Declined");
        setDecline(true);
        setTimeout(() => {
          history("/admin/verify-investor");
        }, 500);
      })
      .catch(() => {
        toast.error("Some Error");
        setDecline(false);
      });
  };

  const fetchDocument = async () => {
    await Axios({
      method: "GET",
      url: `/api/admin/documents/${investor.id}`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        setdocument(res.data);
      })
      .catch(() => console.log("error"));
  };
  const sendReview = async () => {
    if (!confirm("Are you sure you want to Send this review?")) {
      return;
    }
    const data = {
      id: investor.id,
      reason: review,
    };
    await Axios.post("/api/admin/review", data)
      .then(() => {
        toast.success("Successfully Sent Review");
      })
      .catch(() => {
        toast.error("some error");
      });
  };
  useEffect(() => {
    fetchDocument();
  }, []);

  return (
    <>
      <div className="admin-root container">
        <div className="founder-verify-main">
          <div className="verify-founder-head">
            <div className="verify-investor-head-img">
              <figure>
                <img
                  src={investor.profilePic}
                  alt="aman"
                  className="img-fluid"
                />
              </figure>
              <figcaption>
                <div className="verify-investor-details">
                  <p className="verify-investor-name">{investor.name}</p>
                </div>
              </figcaption>
            </div>
            <div className="verify-founder-entities">
              <div className="entityName">
                <h3>Entities</h3>
              </div>
              <div className="previousInvestment">
                <p>Previous Investments in</p>
                <div className={classes.root}>
                  <Chip
                    label={investor.investedValue}
                    clickable
                    color="primary"
                    variant="outlined"
                  />
                  {/* {investor.PreviousInvestment.map((investment, i) => {
                    return (
                      <Chip
                        label={investment}
                        clickable
                        color="primary"
                        variant="outlined"
                        key={i}
                      />
                    );
                  })} */}
                </div>
              </div>
              <div className="intrestedin">
                <p>Interested in</p>
                <div className={classes.root}>
                  {investor.interestedDomains.map((interest, i) => {
                    return (
                      <Chip
                        label={interest}
                        clickable
                        color="primary"
                        variant="outlined"
                        key={i}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <hr className="verify-founder-hr" />
          <div className="verify-investor-main-details">
            <div className="all-main-details">
              {/* <div className="allinfo-details-item">
                <PhoneIcon />
                <p>{investor.phone}</p>
              </div> */}
              <div className="allinfo-details-item">
                <AlternateEmailRoundedIcon />
                <p>{investor.contact.email}</p>
              </div>
              <div className="allinfo-details-item">
                <LinkedInIcon />
                <a
                  href={investor.socialConnects.linkedProfile}
                  target="_blank"
                  rel="noreferrer"
                >
                  {investor.socialConnects.linkedProfile}
                </a>
              </div>
              <div className="allinfo-details-item">
                <ImageIcon />
                {document && document.documents ? (
                  <a
                    href={document.documents.photoid}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Photo Id
                  </a>
                ) : null}
              </div>
              <div className="allinfo-details-item">
                <RoomIcon />
                <p>{investor.location.country}</p>
              </div>
              <div className="verify-founder-review">
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setreview(e.target.value)}
                  value={review}
                  className="reviewInput"
                  placeholder="Send review to the Investor"
                />
                <div>
                  <button className="black_CTA_view">ADD REVIEW</button>
                  <button
                    onClick={() => sendReview()}
                    className="gradient_admin_btn"
                  >
                    SEND ALL REVIEW
                  </button>
                </div>
              </div>
            </div>
            <div className="verify-investor-photoId">
              {/*
              <div className="verify-investor-imgicon">
                <ImageIcon />
                <p>Photo Id</p>
              </div>
              <figure>
                <img
                  src={investor.photoId}
                  alt={investor.fullname}
                  className="img-fluid"
                />
              </figure>
              <a href={investor.photoId} target="_blank" rel="noopener noreferrer">
                Download
              </a> */}
              <div className="verifybtn">
                {declined ? (
                  <button className="black_CTA_view">DECLINED</button>
                ) : (
                  <button
                    className="black_CTA_view"
                    onClick={() => handleDecline()}
                  >
                    DECLINE
                  </button>
                )}

                {verified ? (
                  <button className="black_CTA_view">
                    <DoneAllIcon />
                    VERIFIED
                  </button>
                ) : (
                  <button
                    className="black_CTA_view"
                    onClick={() => handleVerify()}
                  >
                    <DoneAllIcon />
                    VERIFY
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default index;
