import React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    width: "100%",
    margin: "5px 0",
    padding: "10px !important",
    "& > *": {
      margin: "5px !important",
      color: "#4ad7d1 !important",
      border: "1px solid #4ad7d1 !important",
      padding: "7px !important",
    },
  },
}));

const Cards = ({ data }) => {
  // const { founder } = data;
  const history = useNavigate();
  const classes = useStyles();
  const handleRedirect = (data) => {
    history(`/admin/verify-fundmanager/${data.id}`, { state: { data } });
  };

  if (!data) {
    console.log(data);
    return "empty";
  }

  return (
    <>
      <div className="logo-div">
        <div className="main-logo">
          <figure>
            <img src={data.fundlogo} alt="bookify" className="img-fluid" />
          </figure>
          <figcaption>
            <p className="companyName">{data.fundName}</p>
            <p className="founderName">{data.fundDescription}</p>
          </figcaption>
        </div>

        <div className={`${classes.root} chips`}>
          <Chip
            icon={<EuroSymbolIcon />}
            label={data.ticketSize}
            clickable
            color="primary"
            variant="outlined"
            className="chipicon"
          />

          <Chip
            icon={<DateRangeIcon />}
            label={data.fundLaunchDate}
            clickable
            color="primary"
            variant="outlined"
            className="chipicon"
          />
        </div>

        <div className="viewBtn">
          <button
            className="gradient_admin_btn"
            onClick={() => handleRedirect(data)}
          >
            View
          </button>
        </div>
      </div>
    </>
  );
};

Cards.propTypes = {
  data: PropTypes.any.isRequired,
};

export default Cards;
