// import React, { useEffect, useState } from "react";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import CardApi from "../API/FounderApi/CardApi";
import "./index.css";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import PublicIcon from "@mui/icons-material/Public";
import BusinessIcon from "@mui/icons-material/Business";

// import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
// import ImageIcon from "@mui/icons-material/Image";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const index = () => {
  const location = useLocation();
  const history = useNavigate();
  const { data } = location.state;
  const [manager] = useState(data);
  const [verified, setVerify] = useState(false);
  const [decline, setDecline] = useState(false);
  const [document, setdocument] = useState();
  const [review, setreview] = useState("");
  const [fundCompany, setfundCompany] = useState("");
  // const { manager } = useParams();
  // const [data, setData] = useState();

  // useEffect(() => {
  //   const newData = CardApi.filter((mydata) => {
  //     return mydata.handle === manager;
  //   });
  //   setData(newData[0]);
  // }, []);

  // verify manager
  const handleVerify = async () => {
    if (!confirm("Are you sure you want to Verify?")) {
      return;
    }
    await Axios.post("/api/admin/handle-fm/accept", manager)
      .then(() => {
        toast.success("Successfully Verified");
        setVerify(true);
        history("/admin/verify-fundmanager");
      })
      .catch(() => {
        toast.error("Some Error");
        setVerify(false);
      });
  };

  // decline manager
  const handleDecline = async () => {
    if (!confirm("Are you sure you want to Decline?")) {
      return;
    }
    await Axios.post("/api/admin/handle-fm/decline", manager)
      .then(() => {
        toast.warning("Successfully Declined");
        setDecline(true);
        setTimeout(() => {
          history("/admin/verify-fundmanager");
        }, 500);
      })
      .catch(() => {
        toast.error("Some Error");
        setDecline(false);
      });
  };
  const fetchCompany = async () => {
    await Axios({
      method: "GET",
      url: `/api/admin/fundcompany/${manager.companyID}`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        setfundCompany(res.data.companyName);
      })
      .catch(() => console.log("error"));
  };
  const fetchDocument = async () => {
    await Axios({
      method: "GET",
      url: `/api/admin/documents/${manager.id}`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        setdocument(res.data.documents);
      })
      .catch(() => console.log("error"));
  };
  const sendReview = async () => {
    if (!confirm("Are you sure you want to Send this review?")) {
      return;
    }
    const data = {
      id: manager.id,
      reason: review,
    };
    await Axios.post("/api/admin/review", data)
      .then(() => {
        toast.success("Successfully Sent Review");
      })
      .catch(() => {
        toast.error("some error");
      });
  };
  useEffect(() => {
    fetchDocument();
    fetchCompany();
  }, []);

  if (!manager) {
    return "Empty";
  }
  if (!fundCompany) {
    return "Empty";
  }

  return (
    <>
      <div className="admin-root container">
        <div className="founder-verify-main">
          <div className="verify-founder-head">
            <div className="verify-founder-head-img">
              <figure>
                <img
                  src={manager.fundlogo}
                  alt={manager.fundName}
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="verify-founder-head-name">
              <p className="verify-founder-company-name">{manager.fundName}</p>

              <p className="verify-founder-company-date">
                FUND LAUNCH DATE : {manager.fundLaunchDate}
              </p>
              <p className="verify-founder-company-date">
                FUND DESCRIPTION : {manager.fundDescription}
              </p>
              <p className="verify-founder-company-date">
                FUND COMPANY : {fundCompany}
              </p>
            </div>
            <div className="verify-founder-head-blank"></div>
          </div>
          <hr className="verify-founder-hr" />
          <div className="verify-founder-info">
            <div className="verify-founder-infomain">
              <EuroSymbolIcon />
              <p>TICKET SIZE: {manager.ticketSize}</p>
            </div>
            <div className="verify-founder-infomain">
              <PeopleAltRoundedIcon />
              CURRENT STAGE:
              {manager.currentStage.map((v, i) => {
                return <p key={i}>{v}</p>;
              })}
            </div>
            <div className="verify-founder-infomain">
              <PublicIcon />
              COUNTRIES:
              {manager.countries.map((v, i) => {
                return <p key={i}>{v}</p>;
              })}
            </div>
            <div className="verify-founder-infomain">
              <BusinessIcon />
              INDUSTRIES:
              {manager.industries.map((v, i) => {
                return <p key={i}>{v}</p>;
              })}
            </div>
          </div>
          <hr className="verify-founder-hr" />
          <div className="w_100">
            <p>Documents</p>
          </div>
          <div className="verify-founder-doc-div">
            <div className="verify-founder-doc">
              {document && document.documents
                ? document.documents.map((v, i) => {
                    return (
                      <>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          key={i}
                          href={v.location}
                          style={{ textDecoration: "none", color: "#fff" }}
                          className="gradient_admin_btn"
                        >
                          View Document
                        </a>
                        <br />
                      </>
                    );
                  })
                : null}
              <div className="verify-founder-review">
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setreview(e.target.value)}
                  value={review}
                  className="reviewInput"
                  placeholder="Send review to the Manager"
                />
                <div>
                  <button className="black_CTA_view">ADD REVIEW</button>
                  <button
                    onClick={() => sendReview()}
                    className="gradient_admin_btn"
                  >
                    SEND ALL REVIEW
                  </button>
                </div>
              </div>
            </div>
            <div className="verify-founder-profile">
              <div className="verify-founder-profile-allinfo"></div>
              <div className="verifybtn">
                {decline ? (
                  <button className="black_CTA_view">DECLINED</button>
                ) : (
                  <button className="black_CTA_view" onClick={handleDecline}>
                    DECLINE
                  </button>
                )}

                {verified ? (
                  <button className="black_CTA_view">
                    <DoneAllIcon />
                    VERIFIED
                  </button>
                ) : (
                  <button className="black_CTA_view" onClick={handleVerify}>
                    <DoneAllIcon />
                    VERIFY
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default index;
