import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoginActivity from "./LoginActivity";
import Insights from "./InsightsActivity";
import Connection from "./Connections";
import Valuation from "./ValuationActitvity";
import Pitchdeck from "./PitchDeckActivity";
import Gd_History from "./Gd_history";
import GD_STARTUPINVITE from "./GlobalDatabase/GDStartupInvite";
import GD_INVESTORINVITE from "./GlobalDatabase/GDInvestorInvite";

import { Link } from "react-router-dom";
import "./index.css";
// import Plans from "./OldPlans/Plans";
import OnlineActivity from "./OnlineActivity";
import Plans from "./NewPlans/plans";
import BullJobLogs from "./JobLogs/jobs";
import EmailLogs from "./EmailLogs/emaillogs";
import { ArrowLeft } from "@mui/icons-material";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Link to="/zr-admin-dashboard">
        <button className="gradient_admin_btn m-4 ms-5">
          <ArrowLeft />
          Back to Dashboard
        </button>
      </Link>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
        }}
        className="dashboard01_main_box"
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
          className="dashboard01_tabs"
        >
          <Tab label="Login Activities" {...a11yProps(0)} />
          <Tab label="Online Activities" {...a11yProps(1)} />
          <Tab label="Connection Activites" {...a11yProps(2)} />
          <Tab label="Valuation Activites" {...a11yProps(3)} />
          <Tab label="Pitch Deck Activites" {...a11yProps(4)} />
          <Tab label="Insights Activites" {...a11yProps(5)} />
          <Tab label="Plan" {...a11yProps(6)} />
          <Tab label="GD History" {...a11yProps(7)} />
          <Tab label="GD Startup Invite" {...a11yProps(8)} />
          <Tab label="GD Investor Invite" {...a11yProps(9)} />
          <Tab label="Cron Jobs" {...a11yProps(10)} />
          <Tab label="Email Logs" {...a11yProps(11)} />
        </Tabs>
        <div className="dashboard01_tabpanel_root">
          <TabPanel value={value} index={0} className="dashboard01_tabpanel">
            <LoginActivity />
          </TabPanel>
          <TabPanel value={value} index={1} className="dashboard01_tabpanel">
            <OnlineActivity />
          </TabPanel>
          <TabPanel value={value} index={2} className="dashboard01_tabpanel">
            <Connection />
          </TabPanel>
          <TabPanel value={value} index={3} className="dashboard01_tabpanel">
            <Valuation />
          </TabPanel>
          <TabPanel value={value} index={4} className="dashboard01_tabpanel">
            <Pitchdeck />
          </TabPanel>
          <TabPanel value={value} index={5} className="dashboard01_tabpanel">
            <Insights />
          </TabPanel>
          <TabPanel value={value} index={6} className="dashboard01_tabpanel">
            <Plans />
          </TabPanel>
          <TabPanel value={value} index={7} className="dashboard01_tabpanel">
            <Gd_History />
          </TabPanel>
          <TabPanel value={value} index={8} className="dashboard01_tabpanel">
            <GD_STARTUPINVITE />
          </TabPanel>
          <TabPanel value={value} index={9} className="dashboard01_tabpanel">
            <GD_INVESTORINVITE />
          </TabPanel>
          <TabPanel value={value} index={10} className="dashboard01_tabpanel">
            <BullJobLogs />
          </TabPanel>
          <TabPanel value={value} index={11} className="dashboard01_tabpanel">
            <EmailLogs />
          </TabPanel>
        </div>
      </Box>
    </>
  );
}
