/** @format */

import { Link } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Axios from "axios";
import VReportCards from "./VReportCards";
export default function ValuationResponses() {
  const [valuations, setValuations] = useState();

  useEffect(() => {
    const fetchValuations = async () => {
      await Axios({
        method: "get",
        url: " /api/admin/valuations",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((res) => {
          setValuations(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchValuations();
  }, []);

  return (
    <div>
      <div className="row no-margin">
        <button className="btn btn-secondary">
          <Link href="/zr-admin-dashboard">Back to Dashboard</Link>
        </button>
      </div>

      <div className="text-center contaniner ">
        <h4 className="p_small_white font20">Valuation Reports</h4>
        <div className="row no-margin">
          {valuations ? (
            <>
              {valuations.map((vreport) => (
                <>
                  <VReportCards valuation={vreport} />
                </>
              ))}
            </>
          ) : (
            <p>No valuations</p>
          )}
        </div>
      </div>
    </div>
  );
}
