import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./index.css";
import Cards from "./FundCompaniesCard/Cards";
// import CardApi from "../API/FounderApi/CardApi";
import Axios from "axios";

const index = () => {
  const [nonverifiedfundCompanies, setnonverifiedfundCompanies] = useState();
  const fetchnonverifiedfundCompanies = async () => {
    await Axios({
      method: "GET",
      url: "/api/admin/handle-fc",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        const { nonverified } = res.data;
        setnonverifiedfundCompanies(nonverified);
      })
      .catch(() => console.log("error"));
  };

  useEffect(() => {
    fetchnonverifiedfundCompanies();
  }, []);

  if (!nonverifiedfundCompanies) {
    return "empty";
  }
  return (
    <>
      <div className="admin-root container">
        <div className="zr-nav">
          <NavLink to="/zr-admin-dashboard" className="p_small_grey2">
            Dashboard
          </NavLink>
          {/* &#47; */}
          <span>&#47;</span>
          <NavLink to="/admin/verify-fundcompany" className="p_small_grey2">
            Pending Fund Company Verifications
          </NavLink>
        </div>

        <div className="main-card-div">
          <div className="founderCard">
            {nonverifiedfundCompanies
              ? nonverifiedfundCompanies.map((company) => (
                  <Cards key={company.id} data={company} />
                ))
              : "No Fund Companies"}
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
