import React from "react";
import { AccessRolesConst, removeFromArr } from "../../../Utils/Roles";

const SelectedRole = ({ roles, setRoles }) => {
  return (
    <div className="zefy-role-multiselect-chips-root mt-20 d-flex align-items-center flex-wrap">
      {roles[AccessRolesConst.valuation_service] && (
        <p className="transparntChip caption mr-12 mb-12">
          {AccessRolesConst.valuation_service}
          <span
            className="ml-16 redAstrick"
            onClick={() =>
              removeFromArr(AccessRolesConst.valuation_service, roles, setRoles)
            }
          >
            X
          </span>
        </p>
      )}
      {roles[AccessRolesConst.pitchdeck_Service] && (
        <p className="transparntChip caption mr-12 mb-12">
          {AccessRolesConst.pitchdeck_Service}
          <span
            className="ml-16 redAstrick"
            onClick={() =>
              removeFromArr(AccessRolesConst.pitchdeck_Service, roles, setRoles)
            }
          >
            X
          </span>
        </p>
      )}
      {roles[AccessRolesConst.insights_Service] && (
        <p className="transparntChip caption mr-12 mb-12">
          {AccessRolesConst.insights_Service}
          <span
            className="ml-16 redAstrick"
            onClick={() =>
              removeFromArr(AccessRolesConst.insights_Service, roles, setRoles)
            }
          >
            X
          </span>
        </p>
      )}
      {roles[AccessRolesConst.event_Management] && (
        <p className="transparntChip caption mr-12 mb-12">
          {AccessRolesConst.event_Management}
          <span
            className="ml-16 redAstrick"
            onClick={() =>
              removeFromArr(AccessRolesConst.event_Management, roles, setRoles)
            }
          >
            X
          </span>
        </p>
      )}
      {roles[AccessRolesConst.partnership_Service] && (
        <p className="transparntChip caption mr-12 mb-12">
          {AccessRolesConst.partnership_Service}
          <span
            className="ml-16 redAstrick"
            onClick={() =>
              removeFromArr(
                AccessRolesConst.partnership_Service,
                roles,
                setRoles
              )
            }
          >
            X
          </span>
        </p>
      )}
      {roles[AccessRolesConst.gd_service] && (
        <p className="transparntChip caption mr-12 mb-12">
          {AccessRolesConst.gd_service}
          <span
            className="ml-16 redAstrick"
            onClick={() =>
              removeFromArr(AccessRolesConst.gd_service, roles, setRoles)
            }
          >
            X
          </span>
        </p>
      )}
      {roles[AccessRolesConst.recruitment_Service] && (
        <p className="transparntChip caption mr-12 mb-12">
          {AccessRolesConst.recruitment_Service}
          <span
            className="ml-16 redAstrick"
            onClick={() =>
              removeFromArr(
                AccessRolesConst.recruitment_Service,
                roles,
                setRoles
              )
            }
          >
            X
          </span>
        </p>
      )}
      {roles[AccessRolesConst.connection_service] && (
        <p className="transparntChip caption mr-12 mb-12">
          {AccessRolesConst.connection_service}
          <span
            className="ml-16 redAstrick"
            onClick={() =>
              removeFromArr(
                AccessRolesConst.connection_service,
                roles,
                setRoles
              )
            }
          >
            X
          </span>
        </p>
      )}
      {roles[AccessRolesConst.mi_service] && (
        <p className="transparntChip caption mr-12 mb-12">
          {AccessRolesConst.mi_service}
          <span
            className="ml-16 redAstrick"
            onClick={() =>
              removeFromArr(AccessRolesConst.mi_service, roles, setRoles)
            }
          >
            X
          </span>
        </p>
      )}
      {roles[AccessRolesConst.admin_service] && (
        <p className="transparntChip caption mr-12 mb-12">
          {AccessRolesConst.admin_service}
          <span
            className="ml-16 redAstrick"
            onClick={() =>
              removeFromArr(AccessRolesConst.admin_service, roles, setRoles)
            }
          >
            X
          </span>
        </p>
      )}
    </div>
  );
};

export default SelectedRole;
