import { Checkbox } from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import BackIcon from "../../../components/Icons/BackIcon";
// import CameraAddIcon from "../Registration/Icons/CameraAdd";
import industries from "../Lists/Industies2.json";
import BusinessModels from "../Lists/BusinessModels.json";
import investmentStagesList from "../Lists/investmentStages.json";
// import "../index.css";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
// import { useAuth } from "../../../Utils/Auth";

const RegisterCorporate = () => {
  const history = useNavigate();
  const { userid } = useParams();
  const [logo, setLogo] = useState(null);
  const [companyName, setcompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [grossValue, setgrossValue] = useState("");
  const [fundTargetVal, setFundTargetValue] = useState("");
  const [industiesArr, setIndustiesArr] = useState(industries);
  const [industrySearch, setIndustrySearch] = useState("");
  const [industryshow, setIndustryShow] = useState(false);
  const [selectedIndustries, setSelectedIndusties] = useState([]);
  const [businessModelArr, setbusinessModelArr] = useState(BusinessModels);
  const [businessModelSearch, setbusinessModelSearch] = useState("");
  const [businessModelshow, setbusinessModelShow] = useState(false);
  const [selectedbusinessModel, setSelectedbusinessModel] = useState([]);
  const [investmentStageArr, setinvestmentStageArr] =
    useState(investmentStagesList);
  const [investmentStageSearch, setinvestmentStageSearch] = useState("");
  const [investmentStageshow, setinvestmentStageShow] = useState(false);
  const [selectedinvestmentStage, setSelectedinvestmentStage] = useState([]);
  const [countries, setcountries] = useState([]);
  const [countrycode, setcountrycode] = useState(null);
  const [states, setstates] = useState([]);
  const [statecode, setstatecode] = useState(null);
  const [cities, setcities] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [selectDoc, setSelectDoc] = useState("");
  const [otherselectDoc, setotherSelectDoc] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [DocumentsNeed, setCompanyDocuments] = useState([]);
  const [acceptTerms, setAccepTerms] = useState(true);
  const [newsletter, setnewsletter] = useState(false);
  // const { user } = useAuth();
  const [submitClick, setSubmitClick] = useState(0);

  const validateField = (value) => {
    if (!value) {
      return false; // Indicates validation failure
    }
    return true; // Indicates validation success
  };

  const handleDocumentsChange = (e) => {
    const file = e.target.files[0];
    if (
      file.type !== "image/jpg" &&
      file.type !== "image/png" &&
      file.type !== "image/jpeg" &&
      file.type !== "application/pdf" &&
      file.type !== "application/msword" &&
      file.type !== "text/plain" &&
      file.type !==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      toast.error("PDF, Doc, Txt, Docx and Image files are allowed!");
      return;
    }
    if (file.size > 2048 * 1024) {
      // Toast({ msg: "File size exceeded 2MB!", code: 0 });
      toast.error("File size exceeded 2MB!");
      return;
    }
    if (selectDoc === "Others" && otherselectDoc.trim().length === 0) {
      return toast.warning("Fill the document name");
    }

    if (
      selectDoc === "Others" &&
      DocumentsNeed.some(
        (doc) =>
          String(doc.docType).toLowerCase() ===
          otherselectDoc.trim().toLowerCase()
      )
    ) {
      return toast.warning("Document already added");
    }

    const formdata = new FormData();
    formdata.append("document", file);
    setIsUploading(true);
    Axios({
      method: "post",
      url: `${process.env.REACT_APP_baseURL}/api/upload/document`,
      data: formdata,
    })
      .then((res) => {
        const { location } = res.data;
        const filename = {
          docType: selectDoc === "Others" ? otherselectDoc.trim() : selectDoc,
          docName: e.target.files[0].name,
          docExt: e.target.files[0].type,
          downloadUrl: location,
        };
        setCompanyDocuments((docs) => [...docs, filename]);
        setIsUploading(false);
        setotherSelectDoc("");
        setSelectDoc("");
        toast.success("uploaded");
      })
      .catch(() => {
        setIsUploading(false);
      });
  };

  const removeDoc = (doc) => {
    try {
      const newDocArr = DocumentsNeed.filter((d) => d.docType !== doc.docType);
      setCompanyDocuments(newDocArr);
      toast.success("Document removed");
    } catch (error) {
      toast.error("can't remove document");
    }
  };

  const addIntoMultiArray = (value, setArray, mainArray, setMainArray) => {
    setArray((prev) => [...prev, value]);
    const newArr = mainArray.filter((arr) => arr !== value);
    setMainArray(newArr);
  };

  const removeFromMultiArray = (value, setArray, mainArray, setMainArray) => {
    setMainArray((prev) => [value, ...prev]);
    const newArr = mainArray.filter((arr) => arr !== value);
    setArray(newArr);
  };

  const handleOffAllToggle = () => {
    setIndustryShow(false);
    setbusinessModelShow(false);
    setinvestmentStageShow(false);
  };

  const fetchcountries = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_baseURL}/api/location/countries`
    );
    setcountries(response.data);
  };

  const fetchstates = async () => {
    if (countrycode) {
      const response = await Axios.get(
        `${process.env.REACT_APP_baseURL}/api/location/country/${countrycode}`
      );
      setstates(response.data);
    }
  };

  const fetchcities = async () => {
    if (countrycode && statecode) {
      const response = await Axios.get(
        `${process.env.REACT_APP_baseURL}/api/location/cities/${countrycode}/${statecode}`
      );
      setcities(response.data);
    }
  };

  useEffect(() => {
    fetchcountries();
  }, []);

  useEffect(() => {
    fetchstates();
  }, [country]);

  useEffect(() => {
    fetchcities();
  }, [state]);

  const handleRegister = async () => {
    setSubmitClick((prev) => prev + 1);
    if (
      logo === null ||
      companyName === null ||
      country === "" ||
      state === "" ||
      fundTargetVal === ""
    ) {
      toast.error("Complete all details");
      return;
    } else if (!DocumentsNeed || !DocumentsNeed.length > 0) {
      toast.error("Please upload a document");
      return;
    } else if (!acceptTerms) {
      toast.error("Please accept terms");
      return;
    }
    await Axios({
      method: "post",
      url: `${process.env.REACT_APP_baseURL}/api/fund/onboard/${userid}`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: {
        location: {
          country: country,
          state: state,
          city: city,
        },
        profilePic: logo,
        website: website,
        // fundRaisingStatus: fundRaisingStatus,
        companyName: companyName,
        targetSize: fundTargetVal,
        newsletter: newsletter,
        businessModels: selectedbusinessModel.map((ind) => ind.label),
        investmentStages: selectedinvestmentStage.map((ind) => ind.label),
        interestedIndustries: selectedIndustries.map((ind) => ind.label),
        grossValue,
      },
    })
      .then((res) => {
        if (res.status === 250) {
          return toast.error(`Enter valid input in ${res.data.path}`);
        } else if (res.status === 200) {
          toast.success("Fund Company Registered");
          history.push("/admin/all-fund");
        } else return toast.error("Something went wrong!");
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message + " please check your details");
        }
      });
  };

  const handlePicUpload = (e) => {
    const image = e.target.files[0];
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("profilepic", image);
    // setislogoloading(true);
    Axios({
      method: "post",
      url: `/api/upload/profilepic`,
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        // setislogoloading(false);
        setLogo(data.location);
        toast.success("Logo uploaded", { position: "bottom-right" });
      })
      .catch(() => {
        // setislogoloading(false);
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  return (
    <div
      className="startup_register-root"
      style={{ backgroundColor: "white", padding: "100px 0" }}
      onClick={handleOffAllToggle}
    >
      <div className="container">
        <div className="col-3">
          <h1
            className="gradient_admin_btn"
            onClick={() => history("/zr-admin-dashboard")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            Dashboard
          </h1>
        </div>
        <div className="startup_register-back d-flex align-items-center mb-24 pt-24">
          <h1 className="text-center">Fund Company Registration</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10 col-12">
            <div className="startup_register-right-main">
              <div className="startup_register-form-root w_100">
                <div className="startup_register-logo-root mb-24 pb-24 border_bb_ca_half">
                  {logo ? (
                    <div
                      className="startup_register-logo-main position-relative pointer"
                      style={{ overflow: "hidden" }}
                    >
                      <img
                        src={logo}
                        alt="Logo"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <div className="startup_register-logo-rmv">
                        <p
                          className="body2 fw-7"
                          onClick={() => {
                            setLogo("");
                          }}
                        >
                          Remove
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="startup_register-logo-main">
                        <label htmlFor="uploadLogo" className="pointer">
                          <div className="startup_register-logo-down">
                            <p className="body2">Upload</p>
                          </div>
                        </label>
                        <input
                          type="file"
                          name="uploadLogo"
                          id="uploadLogo"
                          onChange={handlePicUpload}
                          accept="image/*"
                        />
                      </div>
                      <div className="ml-lg-24 ml-md-24 ml-sm-24 mt-0 mt-lg-0 mt-md-0 mt-sm-0 mt-24">
                        <p className="body1">Company&apos;s logo</p>
                        <p className="mt-16 body2">
                          <span className="mr-8"></span> Max 2MB Only:{" "}
                          <strong>.png, .jpg, .jpeg</strong>
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="startup_register-form-div border_bb_ca_half pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="cname" className="body1">
                        Company Name <span className="redAstrick">*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12">
                      <input
                        type="text"
                        placeholder="eg: asdfg, Inc"
                        className="body2"
                        name="cname"
                        value={companyName}
                        onChange={(e) => setcompanyName(e.target.value)}
                      />
                      {submitClick > 0 && !validateField(companyName) && (
                        <div className="redAstrick caption mt-6">
                          This Field is mandatory
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="cWebsite" className="body1">
                        Company Website <span className="redAstrick">*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12">
                      <input
                        type="url"
                        placeholder="eg: https://www.example.com/"
                        className="body2"
                        name="cWebsite"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                      {submitClick > 0 && !validateField(website) && (
                        <div className="redAstrick caption mt-6">
                          This Field is mandatory
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="fundsize" className="mb-10 body1">
                        Average total investment value(EURO){" "}
                        <span className="redAstrick">*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12">
                      <select
                        className="body2"
                        name="grossValue"
                        value={grossValue}
                        onChange={(e) => setgrossValue(e.target.value)}
                      >
                        <option className="" value=""></option>
                        <option className="" value="0-100k">
                          0-100k
                        </option>
                        <option className="" value="100k-1M">
                          100k-1M
                        </option>
                        <option className="" value="1M-100M">
                          1M-100M
                        </option>
                        <option className="" value="100M-500M">
                          100M-500M
                        </option>
                        <option className="" value="More than 500M">
                          &gt; 500M
                        </option>
                      </select>
                      {submitClick > 0 && !validateField(grossValue) && (
                        <div className="redAstrick caption mt-6">
                          This Field is mandatory
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="fundsize" className="mb-10 body1">
                        Amount or ticket size you are willing to invest (in
                        Euros) <span className="redAstrick">*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12">
                      <select
                        className="body2"
                        name="chequesize"
                        value={fundTargetVal}
                        onChange={(e) => setFundTargetValue(e.target.value)}
                      >
                        <option className="" value=""></option>
                        <option className="" value="0-100k">
                          0-100k
                        </option>
                        <option className="" value="100k-1M">
                          100k-1M
                        </option>
                        <option className="" value="1M-100M">
                          1M-100M
                        </option>
                        <option className="" value="100M-500M">
                          100M-500M
                        </option>
                        <option className="" value="More than 500M">
                          &gt; 500M
                        </option>
                      </select>
                      {submitClick > 0 && !validateField(fundTargetVal) && (
                        <div className="redAstrick caption mt-6">
                          This Field is mandatory
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="interestedIndustries" className="body1">
                        Interested Industries{" "}
                        <span className="redAstrick">*</span>
                      </label>
                    </div>
                    <div
                      className="col-lg-8 col-md-7 col-12 position-relative"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type="text"
                        placeholder="eg: asdfg, Inc"
                        className="body2"
                        name="industries"
                        onFocus={() => {
                          setIndustryShow(true);
                          setbusinessModelShow(false);
                          setinvestmentStageShow(false);
                        }}
                        value={industrySearch}
                        onChange={(e) => setIndustrySearch(e.target.value)}
                      />
                      {submitClick > 0 && selectedIndustries.length === 0 && (
                        <div className="redAstrick caption mt-6">
                          This Field is mandatory
                        </div>
                      )}
                      <div className="d-flex align-items-center mt-16 flex-wrap">
                        {selectedIndustries.map((val, i) => (
                          <p key={i} className="p_chip mr-10">
                            {val.label}{" "}
                            <span
                              className="redAstrick pointer ml-8"
                              onClick={() =>
                                removeFromMultiArray(
                                  val,
                                  setSelectedIndusties,
                                  selectedIndustries,
                                  setIndustiesArr
                                )
                              }
                            >
                              x
                            </span>
                          </p>
                        ))}
                      </div>
                      {industryshow && (
                        <div className="investor-multiselect-industries-root">
                          {industiesArr
                            .filter((ind) =>
                              String(ind.label)
                                .toLowerCase()
                                .includes(industrySearch.toLowerCase())
                            )
                            .map((industry) => (
                              <div
                                key={industry.id}
                                className="investor-multiselect-industries-main"
                                onClick={() =>
                                  addIntoMultiArray(
                                    industry,
                                    setSelectedIndusties,
                                    industiesArr,
                                    setIndustiesArr
                                  )
                                }
                              >
                                <p className="body2">{industry.label}</p>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="interestedIndustries" className="body1">
                        Interested Business Models{" "}
                        <span className="redAstrick">*</span>
                      </label>
                    </div>
                    <div
                      className="col-lg-8 col-md-7 col-12 position-relative"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type="text"
                        placeholder="eg: asdfg, Inc"
                        className="body2"
                        name="industries"
                        onFocus={() => {
                          setbusinessModelShow(true);
                          setIndustryShow(false);
                          setinvestmentStageShow(false);
                        }}
                        value={businessModelSearch}
                        onChange={(e) => setbusinessModelSearch(e.target.value)}
                      />
                      {submitClick > 0 &&
                        selectedbusinessModel.length === 0 && (
                          <div className="redAstrick caption mt-6">
                            This Field is mandatory
                          </div>
                        )}
                      <div className="d-flex align-items-center mt-16 flex-wrap">
                        {selectedbusinessModel.map((val, i) => (
                          <p key={i} className="p_chip mr-10">
                            {val.label}{" "}
                            <span
                              className="redAstrick pointer ml-8"
                              onClick={() =>
                                removeFromMultiArray(
                                  val,
                                  setSelectedbusinessModel,
                                  selectedbusinessModel,
                                  setbusinessModelArr
                                )
                              }
                            >
                              x
                            </span>
                          </p>
                        ))}
                      </div>
                      {businessModelshow && (
                        <div className="investor-multiselect-industries-root">
                          {businessModelArr
                            .filter((ind) =>
                              String(ind.label)
                                .toLowerCase()
                                .includes(businessModelSearch.toLowerCase())
                            )
                            .map((business) => (
                              <div
                                key={business.id}
                                className="investor-multiselect-industries-main"
                                onClick={() =>
                                  addIntoMultiArray(
                                    business,
                                    setSelectedbusinessModel,
                                    businessModelArr,
                                    setbusinessModelArr
                                  )
                                }
                              >
                                <p className="body2">{business.label}</p>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="interestedIndustries" className="body1">
                        At what stages do you typically invest{" "}
                        <span className="redAstrick">*</span>
                      </label>
                    </div>
                    <div
                      className="col-lg-8 col-md-7 col-12 position-relative"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type="text"
                        placeholder="eg: asdfg, Inc"
                        className="body2"
                        name="investmentStage"
                        onFocus={() => {
                          setinvestmentStageShow(true);
                          setIndustryShow(false);
                          setbusinessModelShow(false);
                        }}
                        value={investmentStageSearch}
                        onChange={(e) =>
                          setinvestmentStageSearch(e.target.value)
                        }
                      />
                      {submitClick > 0 && selectedinvestmentStage && (
                        <div className="redAstrick caption mt-6">
                          This Field is mandatory
                        </div>
                      )}
                      <div className="d-flex align-items-center mt-16 flex-wrap">
                        {selectedinvestmentStage.map((val, i) => (
                          <p key={i} className="p_chip mr-10">
                            {val.label}{" "}
                            <span
                              className="redAstrick pointer ml-8"
                              onClick={() =>
                                removeFromMultiArray(
                                  val,
                                  setSelectedinvestmentStage,
                                  selectedinvestmentStage,
                                  setinvestmentStageArr
                                )
                              }
                            >
                              x
                            </span>
                          </p>
                        ))}
                      </div>
                      {investmentStageshow && (
                        <div className="investor-multiselect-industries-root">
                          {investmentStageArr
                            .filter((ind) =>
                              String(ind.label)
                                .toLowerCase()
                                .includes(investmentStageSearch.toLowerCase())
                            )
                            .map((investment) => (
                              <div
                                key={investment.id}
                                className="investor-multiselect-industries-main"
                                onClick={() =>
                                  addIntoMultiArray(
                                    investment,
                                    setSelectedinvestmentStage,
                                    investmentStageArr,
                                    setinvestmentStageArr
                                  )
                                }
                              >
                                <p className="body2">{investment.label}</p>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half pb-24 mt-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="cname" className="body1">
                        Country <span className="redAstrick">*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12">
                      <select
                        className="body2"
                        name="country"
                        // value={country}
                        onChange={(e) => {
                          setcountrycode(e.target.value.split("#")[0]);
                          setCountry(e.target.value.split("#")[1]);
                        }}
                      >
                        <option value="">Choose country</option>
                        {countries.map((option, i) => (
                          <option
                            className=""
                            key={i}
                            value={`${option.iso2}` + "#" + `${option.name}`}
                          >
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {submitClick > 0 && !validateField(country) && (
                        <div className="redAstrick caption mt-6">
                          This Field is mandatory
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 mb-lg-0 mb-md-0 mb-sm-24 mb-24">
                      <label htmlFor="state" className="mb-10 body1">
                        State <span className="redAstrick">*</span>
                      </label>
                      <select
                        name="state"
                        className={`body2 ${
                          states.length === 0 && "selectDisabled"
                        }`}
                        onChange={(e) => {
                          setstatecode(e.target.value.split("#")[0]);
                          setState(e.target.value.split("#")[1]);
                        }}
                        disabled={states.length > 0 ? false : true}
                      >
                        <option value="">Choose state</option>
                        {states.map((option, i) => (
                          <option
                            className=""
                            key={i}
                            value={`${option.iso2}` + "#" + `${option.name}`}
                          >
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {submitClick > 0 && !validateField(state) && (
                        <div className="redAstrick caption mt-6">
                          This Field is mandatory
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="state" className="mb-10 body1">
                        City <span className="redAstrick">*</span>
                      </label>
                      <select
                        name="city"
                        className={`body2 ${
                          cities.length === 0 && "selectDisabled"
                        }`}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        disabled={cities.length > 0 ? false : true}
                      >
                        <option value="">Choose city</option>
                        {cities.map((option, i) => (
                          <option className="" key={i} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {submitClick > 0 && !validateField(city) && (
                        <div className="redAstrick caption mt-6">
                          This Field is mandatory
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="startup_register-doc-root mt-24 pb-24">
                  <label htmlFor="documents">
                    Please upload relevant documents to verify the credibility
                    of your investment company.{" "}
                    <span className="redAstrick">*</span>
                  </label>
                  <div className="startup_register-form-div row align-items-center mt-10">
                    <div className="col-lg-7 col-md-7 col-sm-7 col-12">
                      <select
                        style={{ height: 49 }}
                        value={selectDoc}
                        onChange={(e) => {
                          if (otherselectDoc === "Others") {
                            setSelectDoc(e.target.value);
                            return;
                          }
                          if (DocumentsNeed.length === 0) {
                            setSelectDoc(e.target.value);
                          } else if (
                            DocumentsNeed.length > 0 &&
                            DocumentsNeed.some(
                              (doc) => doc.docType === e.target.value
                            )
                          ) {
                            toast.warn("Document already added");
                            setSelectDoc("");
                          } else {
                            setSelectDoc(e.target.value);
                          }
                        }}
                      >
                        <option value="">Document Type</option>
                        <option value="Company Registration Document">
                          Registration Document
                        </option>
                        <option value="Incubation Document">
                          Tax Identification Document
                        </option>
                        <option value="Grant Document">Utility Bill</option>
                        <option value="others">Others</option>
                      </select>
                      {submitClick > 0 && DocumentsNeed.length === 0 && (
                        <div className="redAstrick caption mt-6">
                          This Field is mandatory
                        </div>
                      )}
                    </div>
                    {selectDoc && selectDoc !== "Others" && (
                      <div className="col-lg-5 col-md-5 col-sm-5 mt-lg-0 mt-md-0 mt-sm-0 mt-24 text-end">
                        <div className="">
                          <input
                            type="file"
                            id="uploadDoc"
                            style={{ display: "none" }}
                            onChange={handleDocumentsChange}
                          />
                          <label
                            htmlFor="uploadDoc"
                            className="primary_CTA03 pointer"
                          >
                            Upload document
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {selectDoc === "Others" && (
                  <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                    <div className="row">
                      <div className="col-lg-8 col-md-7 col-6">
                        <input
                          type="text"
                          placeholder="Document Name"
                          className="body2"
                          style={{ height: 49 }}
                          value={otherselectDoc}
                          onChange={(e) => setotherSelectDoc(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-4 col-md-5 col-6 text-end">
                        <div className="">
                          <input
                            type="file"
                            id="uploadDoc"
                            style={{ display: "none" }}
                            onChange={handleDocumentsChange}
                          />
                          <label
                            htmlFor="uploadDoc"
                            className="primary_CTA03 pointer"
                          >
                            Upload document
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {
                  <div className="startup_register-doc-uploaded">
                    {DocumentsNeed.length > 0 &&
                      DocumentsNeed.map((doc, i) => (
                        <div className="row mt-16" key={i}>
                          <div className="col-lg-9 col-md-8 col-7">
                            <div className="startup_register-doc-input">
                              <span className="startup_register-doc-span"></span>{" "}
                              <p className="body2">{doc.docType}</p>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4 col-5 text-end">
                            <p
                              className="body2 redAstrick pointer"
                              onClick={() => removeDoc(doc)}
                            >
                              Remove
                            </p>
                          </div>
                        </div>
                      ))}
                    {isUploading && <Spinner />}
                  </div>
                }
              </div>
            </div>
            <div className="startup_register-basic-details-root mt-40 mb-40">
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={newsletter}
                  onChange={(e) => setnewsletter(e.target.checked)}
                  sx={{
                    color: "#CACACA",
                    "&.Mui-checked": {
                      color: "#000",
                    },
                  }}
                />
                <label htmlFor="subscribe" className="body2 ml-16">
                  Subscribe to our Newsletter
                </label>
              </div>
              <div className="d-flex align-items-center mt-24">
                <Checkbox
                  checked={acceptTerms}
                  onChange={(e) => setAccepTerms(e.target.checked)}
                  sx={{
                    color: "#CACACA",
                    "&.Mui-checked": {
                      color: "#000",
                    },
                  }}
                />
                <label htmlFor="term" className="body2 ml-16">
                  By registering, you agree to The Zefyron Network&apos;s{" "}
                  <strong>
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms & conditions
                    </a>
                  </strong>
                </label>
              </div>
              <button className="primary_CTA03 mt-40" onClick={handleRegister}>
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterCorporate;
