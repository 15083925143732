import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

const index = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [values, setValues] = useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const history = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
    };
    await Axios.post("/api/contentadmin/login", data)
      .then((res) => {
        if (res.status === 200) {
          history("/content-admindashboard");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) toast.error("Invalid Credentials!");
      });
  };
  return (
    <div className="auth_root">
      <div className="container auth_container">
        <div className="auth_form">
          <p style={{ color: "white" }}>Content Admin Login</p>
          <form onSubmit={handleSubmit}>
            <input
              id="outlined-basic"
              placeholder="Enter your email"
              required
              type="email"
              className="auth_input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <label className="auth_label">
              <input
                className="auth_input auth_input_password"
                placeholder="Enter your password"
                required
                type={!values.showPassword ? "password" : "text"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="auth_input_pas_btn"
                type="button"
                onClick={() => handleClickShowPassword()}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </button>
            </label>
            <div className="loginbtns">
              <button className="auth_CTA gradient_CTA" type="submit">
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default index;
