import React from "react";
import Axios from "axios";
import { CircularProgress } from "@mui/material";

export default function Activity() {
  const [activity, setactivity] = React.useState([]);

  const fetchStats = async () => {
    const { data } = await Axios({
      method: "get",
      url: "/api/activity/pitchdecks",
    });
    setactivity(data.data);
  };
  React.useEffect(() => {
    fetchStats();
  }, []);
  if (activity === null) {
    return <CircularProgress />;
  }
  return (
    <div className="container admin-user-activity">
      <h3>Pitch Deck Activity</h3>
      {activity ? (
        <>
          <table>
            <tr>
              <th className="">User</th>
              <th className="">Title</th>
              <th className="">Industry</th>
              <th className="">Time</th>
            </tr>
            {activity.length > 0 &&
              activity.map((res, i) => (
                <tr key={i} className="my-2 pt-2">
                  <td className=" my-2 pt-2">
                    {`${res.userId.name} (${res.userId.email})`}
                  </td>
                  <td className=" my-2 pt-2">{res.name}</td>
                  <td className=" my-2 pt-2">{res.industry}</td>
                  <td className="">
                    {new Date(res.updatedAt).toLocaleTimeString()}{" "}
                    {new Date(res.updatedAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
          </table>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
