const CryptoJS = require("crypto-js");
const key = CryptoJS.enc.Utf8.parse("kf;WtH}yb/zTo!H=9}DIoS?2{_V&b/md");
const iv = CryptoJS.lib.WordArray.random(16);
const encryptPreHashed = (plainText) => {
  const encryptedText = CryptoJS.AES.encrypt(plainText, key, {
    iv: iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encryptedText.toString();
};

export { encryptPreHashed };
