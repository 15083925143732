import moment from "moment";
import React, { useState } from "react";

export default function Users({ leads }) {
  const [type, settype] = useState("");
  const [plantype, setplantype] = useState("");
  const [status, setstatus] = useState("");
  const [searhName, setsearhName] = useState("");

  return (
    <div className="white_bg fw-7 sales_users_table" style={{ height: "100%" }}>
      <div className="d-flex mb-2 align-items-end">
        <div className="d-flex">
          <p>
            <span
              style={{
                height: "30px",
                width: "30px",
                background: "green",
                color: "green",
                padding: "0px 3px",
              }}
            >
              ss
            </span>{" "}
            &nbsp; Verified
          </p>
          &nbsp; &nbsp;
          <p>
            <span
              style={{
                height: "30px",
                width: "30px",
                background: "yellow",
                color: "yellow",
                padding: "0px 3px",
              }}
            >
              ss
            </span>
            &nbsp; Registered
          </p>
          &nbsp; &nbsp;
          <p>
            <span
              style={{
                height: "30px",
                width: "30px",
                background: "red",
                color: "red",
                padding: "0px 3px",
              }}
            >
              ss
            </span>
            &nbsp; Sign Up
          </p>
        </div>
        <p className="ms-3 fw-7 ms-auto">
          <span className="fw-6">
            {leads &&
              leads
                .filter((lead) => {
                  if (
                    lead.type !== "fundmanager" &&
                    lead.type !== "corporateuser" &&
                    lead.type !== "academiauser"
                  )
                    return lead;
                })
                .filter((lead) => {
                  if (type === "") return lead;
                  else if (lead.type === type) return lead;
                })
                .filter((lead) => {
                  if (plantype === "") return lead;
                  else if (lead.plan.planName === plantype) return lead;
                })
                .filter((lead) => {
                  if (status === "") return lead;
                  else if (
                    status === "Verified" &&
                    (lead.currentRegistrationStep === 10 || lead.name) &&
                    lead.isProfileVerified
                  )
                    return lead;
                  else if (
                    status === "Registered" &&
                    (lead.currentRegistrationStep === 10 || lead.name) &&
                    !lead.isProfileVerified
                  )
                    return lead;
                  else if (
                    status === "Sign Up" &&
                    lead.currentRegistrationStep !== 10 &&
                    !lead.isProfileVerified
                  )
                    return lead;
                })
                .filter((lead) => {
                  if (searhName === "") return lead;
                  else if (
                    lead.name
                      ?.toLowerCase()
                      .includes(searhName.toLowerCase()) ||
                    lead.firstname
                      ?.toLowerCase()
                      .includes(searhName.toLowerCase()) ||
                    lead.lastname
                      ?.toLowerCase()
                      .includes(searhName.toLowerCase())
                  )
                    return lead;
                }).length}
          </span>{" "}
          Results
        </p>
        <div className="w_33 ms-3">
          <p className="mb-1">Name</p>
          <input
            placeholder="Search Name"
            className="w-100 h-40px"
            value={searhName}
            onChange={(e) => setsearhName(e.target.value)}
          />
        </div>
        <div className="ms-3">
          <p className="mb-1">Type</p>
          <select
            placeholder="Type"
            className="h-40px w-120px"
            value={type}
            onChange={(e) => settype(e.target.value)}
          >
            <option value="">All</option>
            <option value="startup">Startup</option>
            <option value="investor">Investor</option>
            <option value="fundcompany">Fund Company</option>
            <option value="corporate">Corporate</option>
            <option value="institute">Academia</option>
          </select>
        </div>
        <div className="ms-3">
          <p className="mb-1" htmlFor="typeDropdown">
            Status
          </p>{" "}
          <select
            id="typeDropdown"
            className="h-40px w-120px"
            value={status}
            onChange={(e) => setstatus(e.target.value)}
          >
            <option value="">All</option>
            <option value="Verified">Verified</option>
            <option value="Registered">Registered</option>
            <option value="Sign Up">Only Signup</option>
          </select>
        </div>
        <div className="ms-3">
          <p className="mb-1" htmlFor="typeDropdown">
            Plan
          </p>{" "}
          <select
            id="typeDropdown"
            className="h-40px w-120px"
            value={plantype}
            onChange={(e) => setplantype(e.target.value)}
          >
            <option value="">All</option>
            <option value="Free">Free</option>
            <option value="Freemium">Freemium</option>
            <option value="Basic">Basic</option>
            <option value="Premium">Premium</option>
          </select>
        </div>
      </div>
      <table style={{ width: "100%" }}>
        <tr>
          <th>Status</th>

          <th>Type</th>
          <th>Name</th>
          <th>Contact Person</th>
          <th>Email</th>
          <th>Plan</th>
          <th>Phone</th>
          <th>Date</th>
          <th>Location</th>
        </tr>
        {leads
          .filter((lead) => {
            if (
              lead.type !== "fundmanager" &&
              lead.type !== "corporateuser" &&
              lead.type !== "academiauser"
            )
              return lead;
          })
          .filter((lead) => {
            if (type === "") return lead;
            else if (lead.type === type) return lead;
          })
          .filter((lead) => {
            if (plantype === "") return lead;
            else if (lead.plan.planName === plantype) return lead;
          })
          .filter((lead) => {
            if (status === "") return lead;
            else if (
              status === "Verified" &&
              (lead.currentRegistrationStep === 10 || lead.name) &&
              lead.isProfileVerified
            )
              return lead;
            else if (
              status === "Registered" &&
              (lead.currentRegistrationStep === 10 || lead.name) &&
              !lead.isProfileVerified
            )
              return lead;
            else if (
              status === "Sign Up" &&
              lead.currentRegistrationStep !== 10 &&
              !lead.isProfileVerified
            )
              return lead;
          })
          .filter((lead) => {
            if (searhName === "") return lead;
            else if (
              lead.name?.toLowerCase().includes(searhName.toLowerCase()) ||
              lead.firstname?.toLowerCase().includes(searhName.toLowerCase()) ||
              lead.lastname?.toLowerCase().includes(searhName.toLowerCase())
            )
              return lead;
          })
          .map((lead, i) => (
            <tr key={i}>
              <td className="text_align_center ">
                {["startup", "investor", "fundcompany"].includes(lead.type)
                  ? `${lead.currentRegistrationStep} `
                  : ""}
                <span
                  style={{
                    height: "30px",
                    width: "30px",
                    background:
                      lead.currentRegistrationStep === 10 &&
                      lead.isProfileVerified
                        ? "green"
                        : lead.isProfileVerified && lead.name
                        ? "green"
                        : lead.currentRegistrationStep === 10 &&
                          !lead.isProfileVerified
                        ? "yellow"
                        : "red",
                    color:
                      lead.currentRegistrationStep === 10 &&
                      lead.isProfileVerified
                        ? "green"
                        : lead.isProfileVerified && lead.name
                        ? "green"
                        : lead.currentRegistrationStep === 10 &&
                          !lead.isProfileVerified
                        ? "yellow"
                        : "red",
                  }}
                >
                  ss
                </span>
              </td>

              <td className="text_capi fw-5">
                {lead.type === "fundcompany"
                  ? "Fund Company"
                  : lead.type === "institute"
                  ? "Academia"
                  : lead.type}
              </td>
              <td className="fw-5">{lead.name}</td>
              <td className="fw-5">{lead.firstname + " " + lead.lastname}</td>
              <td className="fw-5">
                <a
                  href={`mailto:${lead.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {lead.email}
                </a>
              </td>
              <td className="fw-5">{lead.plan.planName}</td>
              <td className="fw-5">{lead.phone}</td>
              <td className="fw-5">{moment(lead.date).format("DD-MM-YYYY")}</td>
              <td className="fw-5">
                {lead.logindevices[0]?.iplocation?.country}
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
}
