const localNumber = (val) => {
  return val
    ? Number(val).toLocaleString(undefined, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    : Number(0).toLocaleString(undefined, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
};
module.exports = localNumber;
