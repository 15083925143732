import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../NonVerifyFounders/index.css";
import Cards from "./InvestorCards/Cards";
// import CardApi from "../API/InvestorApi/CardApi";
import "./index.css";
import Axios from "axios";

const index = () => {
  const [nonVerifiedInvestor, setNonVerifiedInvestor] = useState();
  const fetchNonVerifiedFounders = async () => {
    await Axios({
      method: "GET",
      url: "/api/admin/handle-investors",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        const { nonverifiedInvestors } = res.data;
        console.log(nonverifiedInvestors);
        setNonVerifiedInvestor(nonverifiedInvestors);
      })
      .catch(() => console.log("error"));
  };

  useEffect(() => {
    fetchNonVerifiedFounders();
    console.log(nonVerifiedInvestor);
  }, []);

  if (!nonVerifiedInvestor) {
    return "empty";
  }
  return (
    <>
      <div className="admin-root container">
        <div className="zr-nav">
          <NavLink to="/zr-admin-dashboard" className="p_small_grey2">
            Dashboard
          </NavLink>
          {/* &#47; */}
          <span>&#47;</span>
          <NavLink to="/admin/verify-founder" className="p_small_grey2 active">
            Pending Investor Verifications
          </NavLink>
        </div>

        <div className="main-card-div">
          <div className="founderCard">
            {nonVerifiedInvestor
              ? nonVerifiedInvestor.map((investor, i) => {
                  return <Cards data={investor} key={i} />;
                })
              : "No Founders"}
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
