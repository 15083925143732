import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  borderRadius: "10px",
  bgcolor: "background.paper",
  boxShadow: 24,

  p: 4,
};
const articles = () => {
  const [news, setnews] = useState([]);
  const history = useNavigate();
  const [description, setdescription] = useState("");
  const [link, setlink] = useState("");
  const [industries, setIndustries] = useState([]);
  const [image, setimage] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = (description, image, industries, link) => {
    setdescription(description);
    setimage(image);
    setIndustries(industries);
    setlink(link);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const fetchnews = async () => {
    try {
      const response = await Axios.get("/api/adminlatest/all");
      if (response.data.success) setnews(response.data.data);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };
  const deletenews = async (id) => {
    try {
      if (!confirm("Are you sure you want to delete this news")) return;
      const response = await Axios.delete(`/api/adminlatest/delete/${id}`);
      if (response.data.success) toast.success("News deleted successfully");
      fetchnews();
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };
  useEffect(() => {
    fetchnews();
  }, []);

  return (
    <>
      <div className="container mt-5 admin-user-activity">
        <div className="row no-margin">
          <p className="white ">
            <Link to="/content-admindashboard">Back to Dashboard</Link>
          </p>
        </div>
        <p className="white font18">Posted Articles</p>

        <table>
          <tr>
            <th className="white">Date</th>
            <th className="white">Subject</th>
            <th className="white">More</th>
            <th className="white">Edit</th>
            <th className="white">Delete</th>
          </tr>
          {news.length > 0 &&
            news.map((res, i) => (
              <tr key={i} className="my-2 pt-2">
                <td className="white my-2 pt-2">
                  {new Date(res.createdAt).toLocaleString()}
                </td>
                <td className="white">{res.Subject} </td>
                <td
                  onClick={() =>
                    handleOpen(
                      res.Description,
                      res.Image,
                      res.Industry,
                      res.Link
                    )
                  }
                  style={{ cursor: "pointer" }}
                  className="white"
                >
                  View
                </td>
                <td
                  onClick={() => history(`/article-upload?id=${res._id}`)}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  Edit
                </td>
                <td
                  onClick={() => deletenews(res._id)}
                  style={{ color: "red", cursor: "pointer" }}
                >
                  Delete
                </td>
              </tr>
            ))}
        </table>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="card">
            <img
              className="card-img-top"
              src={image}
              style={{
                height: "150px",
                width: "fit-content",
                margin: "0 auto",
              }}
              alt="Card image cap"
            />
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                Industries: {industries.map((v) => v + " ")}
              </li>
              <li className="list-group-item">
                Link: <a href={link}>{link}</a>
              </li>
            </ul>
            <div className="card-body">
              <p className="card-text">{description}</p>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default articles;
