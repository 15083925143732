import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";

import PropTypes from "prop-types";
import axios from "axios";
import { io } from "socket.io-client";

const authContext = createContext();

function useProvideAuth() {
  const [admincode, setadmincode] = useState();
  const [adminEmail, setadminEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [superAdmin, setsuperAdmin] = useState(null);
  const [callSuperAdmin, setcallSuperAdmin] = useState(false);
  const signOut = async () => {
    await axios
      .get(`/api/admin/logout`)
      .then((res) => {
        if (res.data.success) {
          setadmincode(null);
          localStorage.clear();
          // cookies.remove('auth_tk');
        }
      })
      .catch(() => {});
  };
  async function getUser() {
    setIsLoading(true);
    await axios({
      method: "get",
      url: `/api/admin/current`,
    })
      .then((res) => {
        setadmincode(res.data.admincode);
        setIsLoading(false);
      })
      .catch(() => {
        setadmincode(null);
        setIsLoading(false);
      });
  }

  async function getsuperAdmin() {
    setIsLoading(true);
    await axios({
      method: "get",
      url: `http://localhost:5002/api/admin/current_admin`,
    })
      .then((res) => {
        setsuperAdmin(res.data.admincode);
        setIsLoading(false);
      })
      .catch(() => {
        setsuperAdmin(null);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getUser();
  }, [admincode]);

  useEffect(() => {
    getsuperAdmin();
  }, [callSuperAdmin]);

  const socket = useRef();
  const [onlineusers, setonlineusers] = useState([]);

  useEffect(() => {
    socket.current = io("https://seal-app-gmvlh.ondigitalocean.app/");
    if (admincode && socket) {
      socket.current.emit("add-user", {
        adminid: "6368b9d76295ca1dcfc9325b",
        userid: "6368b9d76295ca1dcfc9325b",
      });
    } else
      socket.current.emit("disconnectuser", {
        adminid: "6368b9d76295ca1dcfc9325b",
        userid: "6368b9d76295ca1dcfc9325b",
      });

    const handleOnlineUsers = (data) => {
      setonlineusers(data);
    };
    socket?.current.on("adminonlineusers", handleOnlineUsers);
    return () => {
      socket?.current.off("adminonlineusers", handleOnlineUsers);
    };
  }, [admincode, socket]);
  return {
    admincode,
    setadmincode,
    isLoading,
    signOut,
    onlineusers,
    adminEmail,
    setadminEmail,
    setcallSuperAdmin,
    callSuperAdmin,
    superAdmin,
    setsuperAdmin,
  };
}
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
ProvideAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(authContext);
