import { Info } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import BackIcon from "../../../components/Icons/BackIcon";
// import CameraAddIcon from "../Registration/Icons/CameraAdd";
import industries from "../../Industies2.json";
import "../index.css";
import { useNavigate, useParams } from "react-router-dom";
// import { useAuth } from "../../../Utils/Auth";

const RegisterCorporate = () => {
  const history = useNavigate();
  const { userid } = useParams();

  const [islogoloading, setislogoloading] = useState(false);
  const [industiesArr, setIndustiesArr] = useState(industries);
  const [industrySearch, setIndustrySearch] = useState("");
  const [industryshow, setIndustryShow] = useState(false);
  const [selectedIndustries, setSelectedIndusties] = useState([]);
  const [countries, setcountries] = useState([]);
  const [countrycode, setcountrycode] = useState(null);
  const [states, setstates] = useState([]);
  const [statecode, setstatecode] = useState(null);
  const [cities, setcities] = useState([]);
  const [fileName, setFileName] = useState("");
  const [isDocUploading, setisDocUploading] = useState(false);
  const [acceptTerms, setAccepTerms] = useState(true);
  const [corporateDetails, setCorporateDetails] = useState({
    // uname: "",
    legalname: "",
    description: "",
    employees: "",
    typeOfCorporate: "",
    nameOfCorporateType: "",
    logo: "",
    companySize: 0,
    fieldOfInterest: [],
    partnershipWithZefy: false,
    links: "",
    country: "",
    state: "",
    city: "",
    newsletter: true,
    industries: [],
    document: "",
  });

  const addInputEvent = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCorporateDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const addIntoMultiArray = (value, setArray, mainArray, setMainArray) => {
    setArray((prev) => [...prev, value]);
    const newArr = mainArray.filter((arr) => arr !== value);
    setMainArray(newArr);
  };

  const removeFromMultiArray = (value, setArray, mainArray, setMainArray) => {
    setMainArray((prev) => [value, ...prev]);
    const newArr = mainArray.filter((arr) => arr !== value);
    setArray(newArr);
  };

  const handleOffAllToggle = () => {
    setIndustryShow(false);
  };

  const handlePicUpload = (e) => {
    const image = e.target.files[0];
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("profilepic", image);
    setislogoloading(true);
    Axios({
      method: "post",
      url: `/api/upload/profilepic`,
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        setislogoloading(false);
        setCorporateDetails((prev) => {
          return {
            ...prev,
            logo: data.location,
          };
        });
        toast.success("Logo uploaded", { position: "bottom-right" });
      })
      .catch(() => {
        setislogoloading(false);
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  const fetchcountries = async () => {
    const response = await Axios.get(`/api/location/countries`);
    setcountries(response.data);
  };

  const fetchstates = async () => {
    if (countrycode) {
      const response = await Axios.get(`/api/location/country/${countrycode}`);
      setstates(response.data);
    }
  };

  const fetchcities = async () => {
    if (countrycode && statecode) {
      const response = await Axios.get(
        `/api/location/cities/${countrycode}/${statecode}`
      );
      setcities(response.data);
    }
  };

  const setCompanySize = (s) => {
    setCorporateDetails((prev) => {
      return {
        ...prev,
        companySize: s,
      };
    });
  };

  const handlePhotoIDUpload = (e) => {
    const image = e.target.files[0];
    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg" &&
      image.type !== "application/msword" &&
      image.type !== "application/pdf"
    ) {
      toast.error("Upload pdf, word or image file!");
      return;
    }
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!");
      return;
    }
    const formdata = new FormData();
    formdata.append("photoID", image);
    setisDocUploading(true);
    Axios({
      method: "post",
      url: `/api/upload/photoID`,
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        toast.success("Document uploaded");
        setFileName(image.name);
        setCorporateDetails((prev) => {
          return {
            ...prev,
            document: data.location,
          };
        });
        setisDocUploading(false);
      })
      .catch(() => {
        setisDocUploading(false);
        setCorporateDetails((prev) => {
          return {
            ...prev,
            document: "",
          };
        });
        toast.error("Error in upload");
      });
  };

  useEffect(() => {
    fetchcountries();
  }, []);

  useEffect(() => {
    fetchstates();
  }, [corporateDetails.country]);

  useEffect(() => {
    fetchcities();
  }, [corporateDetails.state]);

  useEffect(() => {
    localStorage.setItem("CorporateDetails", JSON.stringify(corporateDetails));
  }, [corporateDetails]);

  useEffect(() => {
    const profileDetails = JSON.parse(localStorage.getItem("CorporateDetails"));
    if (profileDetails) {
      if (profileDetails.logo) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            logo: profileDetails.logo,
          };
        });
      }
      if (profileDetails.logo)
        if (profileDetails.legalname) {
          setCorporateDetails((prev) => {
            return {
              ...prev,
              legalname: profileDetails.legalname,
            };
          });
        }
      if (profileDetails.typeOfCorporate) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            typeOfCorporate: profileDetails.typeOfCorporate,
          };
        });
      }
      if (profileDetails.nameOfCorporateType) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            nameOfCorporateType: profileDetails.nameOfCorporateType,
          };
        });
      }
      if (profileDetails.companySize) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            companySize: profileDetails.companySize,
          };
        });
      }
      if (profileDetails.fieldOfInterest) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            fieldOfInterest: profileDetails.fieldOfInterest,
          };
        });
      }
      if (profileDetails.partnershipWithZefy) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            partnershipWithZefy: profileDetails.partnershipWithZefy,
          };
        });
      }
      if (profileDetails.links) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            links: profileDetails.links,
          };
        });
      }
      if (profileDetails.newsletter) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            newsletter: profileDetails.newsletter,
          };
        });
      }
      if (profileDetails.document) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            document: profileDetails.document,
          };
        });
      }
      if (profileDetails.description) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            description: profileDetails.description,
          };
        });
      }
      if (profileDetails.employees) {
        setCorporateDetails((prev) => {
          return {
            ...prev,
            employees: profileDetails.employees,
          };
        });
      }
    }
  }, []);

  const handleRegister = async () => {
    if (!corporateDetails.logo) {
      toast.error({ msg: "Upload logo", code: 0 });
      return;
    }
    if (!corporateDetails.document) {
      toast.error({ msg: "Upload document", code: 0 });
      return;
    }
    if (acceptTerms === false) {
      toast.error({ msg: "Please Agree to Terms and Conditions", code: 0 });
      return;
    }
    if (selectedIndustries.length === 0) {
      toast.error({ msg: "Please select industries", code: 0 });
      return;
    }
    if (
      corporateDetails.legalname === "" ||
      corporateDetails.typeOfCorporate === "" ||
      corporateDetails.nameOfCorporateType === "" ||
      corporateDetails.companySize === "" ||
      corporateDetails.fieldOfInterest === "" ||
      corporateDetails.partnershipWithZefy === "" ||
      corporateDetails.links === "" ||
      corporateDetails.country === "" ||
      corporateDetails.state === "" ||
      corporateDetails.employees === "" ||
      corporateDetails.description.length === 0
    ) {
      toast.error({ msg: "Please fill all details", code: 0 });
      return;
    }
    await Axios({
      method: "post",
      url: `/api/corporate/corporate-onboard/${userid}`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: {
        legalname: corporateDetails.legalname,
        typeOfCorporate: corporateDetails.typeOfCorporate,
        nameOfCorporateType: corporateDetails.nameOfCorporateType,
        logo: corporateDetails.logo,
        companySize: corporateDetails.companySize,
        interestedIndustry: selectedIndustries.map((ind) => ind.label),
        fieldOfInterest: corporateDetails.fieldOfInterest,
        partnershipWithZefy: corporateDetails.partnershipWithZefy,
        links: corporateDetails.links,
        newsletter: corporateDetails.newsletter,
        document: corporateDetails.document,
        employees: corporateDetails.employees,
        description: corporateDetails.description,
        location: {
          country: corporateDetails.country,
          state: corporateDetails.state,
          city: corporateDetails.city,
        },
      },
    })
      .then(() => {
        toast.success("Registered");
        history.push("/admin/all-corporates");
        // setIndustiesArr(industries);
        // setIndustrySearch("");
        // setSelectedIndusties([]);
        // setCorporateDetails({
        //   legalname: "",
        //   typeOfCorporate: "",
        //   nameOfCorporateType: "",
        //   logo: "",
        //   companySize: "",
        //   industry: "",
        //   fieldOfInterest: "",
        //   partnershipWithZefy: false,
        //   employees: "",
        //   description: "",
        // });
      })
      .catch(() => {
        toast.error({ msg: "Could not register", code: 0 });
      });
  };

  return (
    <div
      className="startup_register-root"
      style={{ backgroundColor: "white", padding: "100px 0" }}
      onClick={handleOffAllToggle}
    >
      <div className="container">
        <div className="col-3">
          <h1
            className="gradient_admin_btn"
            onClick={() => history("/zr-admin-dashboard")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            Dashboard
          </h1>
        </div>
        <div className="startup_register-back d-flex align-items-center mb-24 pt-24">
          <h1 className="text-center">Corporate Registration </h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10 col-12">
            <div className="startup_register-right-main">
              <div className="startup_register-form-root w_100">
                <div className="startup_register-logo-root mb-24 pb-24 border_bb_ca_half">
                  {islogoloading ? null : corporateDetails.logo ? (
                    <div
                      className="startup_register-logo-main position-relative pointer"
                      style={{ overflow: "hidden" }}
                    >
                      <img
                        src={corporateDetails.logo}
                        alt="Logo"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <div className="startup_register-logo-rmv">
                        <p
                          className="body2 fw-7"
                          onClick={() => {
                            setCorporateDetails((prev) => {
                              return {
                                ...prev,
                                logo: "",
                              };
                            });
                          }}
                        >
                          Remove
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="startup_register-logo-main">
                        <label htmlFor="uploadLogo" className="pointer">
                          <div className="startup_register-logo-down">
                            <p className="body2">Upload</p>
                          </div>
                        </label>
                        <input
                          type="file"
                          name="uploadLogo"
                          id="uploadLogo"
                          onChange={handlePicUpload}
                          accept="image/*"
                        />
                      </div>
                      <div className="ml-lg-24 ml-md-24 ml-sm-24 mt-0 mt-lg-0 mt-md-0 mt-sm-0 mt-24">
                        <p className="body1">Company&apos;s logo</p>
                        <p className="mt-16 body2">
                          <span className="mr-8">
                            <Info />
                          </span>{" "}
                          Max 2MB Only: <strong>.png, .jpg, .jpeg</strong>
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="startup_register-form-div border_bb_ca_half pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="cname" className="body1">
                        Legal Name
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12">
                      <input
                        type="text"
                        placeholder="eg: asdfg, Inc"
                        className="body2"
                        name="legalname"
                        value={corporateDetails.legalname}
                        onChange={addInputEvent}
                      />
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="cname" className="body1">
                        Name of Corporate
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12">
                      <input
                        type="text"
                        placeholder="eg: asdfg, Inc"
                        className="body2"
                        name="nameOfCorporateType"
                        value={corporateDetails.nameOfCorporateType}
                        onChange={addInputEvent}
                      />
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 mb-lg-0 mb-md-0 mb-sm-24 mb-24">
                      <label htmlFor="noofemp" className="mb-10 body1">
                        Type Of Company
                      </label>
                      <select
                        className="body2"
                        name="typeOfCorporate"
                        value={corporateDetails.typeOfCorporate}
                        onChange={addInputEvent}
                      >
                        <option className="" value=""></option>
                        <option className="" value="Corporate Company">
                          Corporate Company
                        </option>
                        <option className="" value="Corporate Business Unit">
                          Corporate Business Unit
                        </option>
                        <option className="" value="Corporate Venture">
                          Corporate Venture
                        </option>
                        <option className="" value="Corporate Incubator">
                          Corporate Incubator
                        </option>
                        <option className="" value="Other">
                          Others
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="noofemp" className="mb-10 body1">
                        No. of Employees
                      </label>
                      <select
                        className="body2"
                        name="employees"
                        value={corporateDetails.employees}
                        onChange={addInputEvent}
                      >
                        <option className="" value=""></option>

                        <option className="" value="<200">
                          &lt; 200
                        </option>
                        <option className="" value=" 200-1000">
                          200-1000
                        </option>
                        <option className="" value="1000-5000">
                          1000-5000
                        </option>
                        <option className="" value=">5000">
                          &gt;5000
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <label htmlFor="cname" className="mb-10 body1">
                    Short description
                  </label>
                  <textarea
                    placeholder="Min 100 words and Max 300 words"
                    className="body2"
                    name="description"
                    value={corporateDetails.description}
                    onChange={addInputEvent}
                  ></textarea>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="interestedIndustries" className="body1">
                        Interested Industries
                      </label>
                    </div>
                    <div
                      className="col-lg-8 col-md-7 col-12 position-relative"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type="text"
                        placeholder="eg: asdfg, Inc"
                        className="body2"
                        name="industries"
                        onFocus={() => setIndustryShow(true)}
                        value={industrySearch}
                        onChange={(e) => setIndustrySearch(e.target.value)}
                      />
                      <div className="d-flex align-items-center mt-16 flex-wrap">
                        {selectedIndustries.map((val, i) => (
                          <p key={i} className="p_chip mr-10">
                            {val.label}{" "}
                            <span
                              className="redAstrick pointer ml-8"
                              onClick={() =>
                                removeFromMultiArray(
                                  val,
                                  setSelectedIndusties,
                                  selectedIndustries,
                                  setIndustiesArr
                                )
                              }
                            >
                              x
                            </span>
                          </p>
                        ))}
                      </div>
                      {industryshow && (
                        <div className="investor-multiselect-industries-root">
                          {industiesArr
                            .filter((ind) =>
                              String(ind.label)
                                .toLowerCase()
                                .includes(industrySearch.toLowerCase())
                            )
                            .map((industry) => (
                              <div
                                key={industry.id}
                                className="investor-multiselect-industries-main"
                                onClick={() =>
                                  addIntoMultiArray(
                                    industry,
                                    setSelectedIndusties,
                                    industiesArr,
                                    setIndustiesArr
                                  )
                                }
                              >
                                <p className="body2">{industry.label}</p>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="FieldofInterest" className="body1">
                        Field of Interest
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12 position-relative">
                      <select
                        className="body2"
                        name="fieldOfInterest"
                        value={corporateDetails.fieldOfInterest}
                        onChange={addInputEvent}
                      >
                        <option className="" value=""></option>
                        <option
                          className=""
                          value="For investments or collaborations in startups"
                        >
                          For investments or collaborations in startups
                        </option>
                        <option
                          className=""
                          value="For accessing global database of startups and investors"
                        >
                          For accessing global database of startups and
                          investors
                        </option>
                        <option
                          className=""
                          value="For accessing blogs and market intelligence"
                        >
                          For accessing blogs and market intelligence
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half pb-24 mt-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="cname" className="body1">
                        Country
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12">
                      <select
                        className="body2"
                        name="country"
                        value={corporateDetails.location}
                        onChange={(e) => {
                          setcountrycode(e.target.value.split("#")[0]);
                          setCorporateDetails((prev) => {
                            return {
                              ...prev,
                              country: e.target.value.split("#")[1],
                            };
                          });
                        }}
                      >
                        <option value="">Choose country</option>
                        {countries.map((option, i) => (
                          <option
                            className=""
                            key={i}
                            value={`${option.iso2}` + "#" + `${option.name}`}
                          >
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 mb-lg-0 mb-md-0 mb-sm-24 mb-24">
                      <label htmlFor="state" className="mb-10 body1">
                        State
                      </label>
                      <select
                        name="state"
                        className={`body2 ${
                          states.length === 0 && "selectDisabled"
                        }`}
                        onChange={(e) => {
                          setstatecode(e.target.value.split("#")[0]);
                          setCorporateDetails((prev) => {
                            return {
                              ...prev,
                              state: e.target.value.split("#")[1],
                            };
                          });
                        }}
                        disabled={states.length > 0 ? false : true}
                      >
                        <option value="">Choose state</option>
                        {states.map((option, i) => (
                          <option
                            className=""
                            key={i}
                            value={`${option.iso2}` + "#" + `${option.name}`}
                          >
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="state" className="mb-10 body1">
                        City
                      </label>
                      <select
                        name="city"
                        className={`body2 ${
                          cities.length === 0 && "selectDisabled"
                        }`}
                        value={corporateDetails.city}
                        onChange={(e) =>
                          setCorporateDetails((prev) => {
                            return {
                              ...prev,
                              city: e.target.value,
                            };
                          })
                        }
                        disabled={cities.length > 0 ? false : true}
                      >
                        <option value="">Choose city</option>
                        {cities.map((option, i) => (
                          <option className="" key={i} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="cname" className="body1">
                        LinkedIn Profile
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12">
                      <input
                        type="text"
                        placeholder="eg: https://www.linkedin.com/in/profilename/"
                        className="body2"
                        name="links"
                        value={corporateDetails.links}
                        onChange={addInputEvent}
                      />
                    </div>
                  </div>
                </div>
                <div className="startup_register-form-div border_bb_ca_half mt-24 pb-24">
                  <label htmlFor="state" className="mb-16 body1">
                    Choose the type of account you want
                  </label>
                  <div className="row">
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12 mb-lg-0 mb-md-10 mb-sm-10 mb-10"
                      onClick={() => setCompanySize(3)}
                    >
                      <div
                        className={
                          (corporateDetails.companySize === 3
                            ? "c_plan_col corporate_plan_main_active"
                            : "c_plan_col") +
                          " d-flex flex-column justify-content-center align-items-center"
                        }
                      >
                        <h6 className="">Basic</h6>
                        <p
                          className={
                            (corporateDetails.companySize === 3 && "fw-6") +
                            " body1"
                          }
                        >
                          3 users access
                        </p>
                      </div>
                    </div>
                    {/* <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10"
                      onClick={() => setCompanySize(10)}
                    >
                      <div
                        className={
                          (corporateDetails.companySize === 10
                            ? "c_plan_col corporate_plan_main_active"
                            : "c_plan_col") +
                          " d-flex flex-column justify-content-center align-items-center"
                        }
                      >
                        <h6 className="">Standard</h6>
                        <p
                          className={
                            (corporateDetails.companySize === 10 && "fw-6") +
                            " body1"
                          }
                        >
                          10 users access
                        </p>
                      </div>
                    </div> */}
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      onClick={() => setCompanySize(15)}
                    >
                      <div
                        className={
                          (corporateDetails.companySize === 15
                            ? "c_plan_col corporate_plan_main_active"
                            : "c_plan_col") +
                          " d-flex flex-column justify-content-center align-items-center"
                        }
                      >
                        <h6 className="">Premium</h6>
                        <p
                          className={
                            (corporateDetails.companySize === 15 && "fw-6") +
                            " body1"
                          }
                        >
                          {/* Recommended for big corporations with large team size */}
                          Customized solution
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="overline mt-8 grey_75">
                    Check detailed pricing plans{" "}
                    <a
                      className="aqua2"
                      href="/services/corporate#pricing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here
                    </a>
                  </p>
                </div>
                <div className="startup_register-form-div mt-24 pb-24">
                  <div className="row">
                    <div className="col-lg-7 col-md-6 col-12 mb-lg-0 mb-md-0 mb-sm-10 mb-10">
                      <label htmlFor="photoid" className="body1">
                        Please upload relevant documents to verify the
                        credibility of your organization.
                      </label>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12">
                      <div className="investor-file-upload">
                        <label htmlFor="photoid" className="investor-file">
                          Choose file
                          <input
                            type="file"
                            name="photoid"
                            id="photoid"
                            accept="application/pdf application/doc application/docx image/*"
                            hidden
                            onChange={(e) => handlePhotoIDUpload(e)}
                          />
                        </label>
                        <div className="investor-file-nofile">
                          {isDocUploading ? null : (
                            <label htmlFor="photoid">
                              {corporateDetails.document
                                ? fileName
                                : "No File Choosen"}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="startup_register-basic-details-root mt-40 mb-40">
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={corporateDetails.partnershipWithZefy}
                  onChange={(e) => {
                    setCorporateDetails((prev) => {
                      return {
                        ...prev,
                        partnershipWithZefy: e.target.checked,
                      };
                    });
                  }}
                  sx={{
                    color: "#CACACA",
                    "&.Mui-checked": {
                      color: "#000",
                    },
                  }}
                />
                <label htmlFor="partnership" className="body2 ml-16">
                  Are you in partnership with Zefyron?
                </label>
              </div>
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={corporateDetails.newsletter}
                  onChange={(e) => {
                    setCorporateDetails((prev) => {
                      return {
                        ...prev,
                        newsletter: e.target.checked,
                      };
                    });
                  }}
                  sx={{
                    color: "#CACACA",
                    "&.Mui-checked": {
                      color: "#000",
                    },
                  }}
                />
                <label htmlFor="subscribe" className="body2 ml-16">
                  Subscribe to our Newsletter
                </label>
              </div>
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={acceptTerms}
                  onChange={(e) => setAccepTerms(e.target.checked)}
                  sx={{
                    color: "#CACACA",
                    "&.Mui-checked": {
                      color: "#000",
                    },
                  }}
                />
                <label htmlFor="term" className="body2 ml-16">
                  By registering, you agree to The Zefyron Network&apos;s{" "}
                  <strong>
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms & conditions
                    </a>
                  </strong>
                </label>
              </div>
              <button className="zefy_CTA_01 mt-40" onClick={handleRegister}>
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterCorporate;
