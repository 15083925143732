import React, { useEffect, useState } from "react";
import Axios from "axios";

import CountriesData from "../../Filteroptions/countries";
import { Pagination } from "@mui/material";
import { IndustryKeyMap } from "./industries";
import { Avatar } from "@mui/material";
import { toast } from "react-toastify";

const ChangeFormat = (val) => {
  let a = Number(val);
  let isNegative = false;
  if (a < 0) {
    a = Math.abs(a);
    isNegative = true;
  }
  let one = false,
    two = false,
    three = false;
  if (a > 1000) {
    a = a / 1000;
    a = Math.round(a * 1000) / 1000;
    one = true;
  }
  if (a > 1000) {
    a = a / 1000;
    a = Math.round(a * 1000) / 1000;
    two = true;
  }
  if (a > 1000) {
    a = a / 1000;
    a = Math.round(a * 1000) / 1000;
    three = true;
  }
  a = a.toFixed(0);
  if (three) {
    return (isNegative ? -a : a) + " B";
  } else if (two) {
    return (isNegative ? -a : a) + " M";
  } else if (one) {
    return (isNegative ? -a : a) + " K";
  } else {
    if (a === 0) return "";
    else return isNegative ? -a : a;
  }
};
const map1 = new Map();
map1.set(0, 0);
map1.set(13, 1);
map1.set(26, 2);
map1.set(39, 3);
map1.set(52, 4);
map1.set(65, 5);
map1.set(78, 6);
map1.set(91, 7);
map1.set(100, 8);

const map2 = new Map();
map2.set(0, 0);
map2.set(11, 1);
map2.set(22, 2);
map2.set(33, 3);
map2.set(44, 4);
map2.set(55, 5);
map2.set(66, 6);
map2.set(77, 7);
map2.set(88, 8);
map2.set(99, 9);
const TotalEmployeeMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 11,
    label: "10",
  },
  {
    value: 22,
    label: "50",
  },
  {
    value: 33,
    label: "100",
  },
  {
    value: 44,
    label: "250",
  },
  {
    value: 55,
    label: "500",
  },
  {
    value: 66,
    label: "1000",
  },
  {
    value: 77,
    label: "5000",
  },
  {
    value: 88,
    label: "10000",
  },
  {
    value: 99,
    label: "∞",
  },
];
const RevenueMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 13,
    label: "$1M",
  },
  {
    value: 26,
    label: "$10M",
  },
  {
    value: 39,
    label: "$50M",
  },
  {
    value: 52,
    label: "$100M",
  },
  {
    value: 65,
    label: "$500M",
  },
  {
    value: 78,
    label: "$1B",
  },
  {
    value: 91,
    label: "$10B",
  },
  {
    value: 100,
    label: "∞",
  },
];
function getKeyByValue(map, searchValue) {
  for (let [key, value] of map.entries()) {
    if (value === searchValue) {
      return key;
    }
  }
  return undefined;
}
const Plans = () => {
  const [history, sethistory] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [searchuser, setsearchuser] = useState("");
  const [fetchedusers, setfetchedusers] = useState([]);
  const [selectedUserId, setselectedUserId] = useState(null);

  const fetchPlans = async () => {
    await Axios({
      method: "get",
      url: `/api/activity/gd_history?page=${page}&size=20&userid=${selectedUserId?._id}`,
    })
      .then((res) => {
        sethistory(res.data.data);
        setCount(res.data.count);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchPlans();
  }, [page, selectedUserId]);
  const getCountry = (index) => {
    var c;
    c = CountriesData.find((con) => con.index === Number(index));
    if (c) return c.name;
    else return c;
  };

  const getIndustryName = (i) => {
    const ind = IndustryKeyMap.find((v) => v.value === i);
    if (ind) return ind.key;
    return "";
  };
  const returnEmployees = (employeeRange1) => {
    if (employeeRange1?.length > 0) {
      const val1 = getKeyByValue(map2, employeeRange1[0]);
      const val2 = getKeyByValue(map2, employeeRange1[1]);

      let v1 = TotalEmployeeMarks.find((item) => {
        if (item.value === val1) return true;
      });
      let v2 = TotalEmployeeMarks.find((item) => {
        if (item.value === val2) return true;
      });
      if (
        employeeRange1 &&
        JSON.stringify([0, 9]) !== JSON.stringify(employeeRange1)
      )
        return `${v1?.label} - ${v2?.label}`;
    }
  };
  const returnrevenue = (employeeRange1) => {
    if (employeeRange1?.length > 0) {
      const val1 = getKeyByValue(map1, employeeRange1[0]);
      const val2 = getKeyByValue(map1, employeeRange1[1]);

      let v1 = RevenueMarks.find((item) => {
        if (item.value === val1) return true;
      });
      let v2 = RevenueMarks.find((item) => {
        if (item.value === val2) return true;
      });
      return `${v1?.label} - ${v2?.label}`;
    }
  };
  const returnDate = (d) => {
    return new Date(d).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };
  const fetchUserByName = async () => {
    try {
      // if (searchuser.trim() === "") {
      //   setselectedUserId(null);
      //   setfetchedusers([]);
      //   return;
      // }
      const response = await Axios.get(`/api/admin/searchuser?s=${searchuser}`);
      if (response.data.success) {
        setfetchedusers(response.data.data);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };
  useEffect(() => {
    if (searchuser?.trim() != "") fetchUserByName();
    else setfetchedusers([]);
  }, [searchuser]);
  return (
    <div className="plan_root">
      <div className="row">
        <div className="col">
          <div>
            <p>Search User</p>
            <input
              style={{ width: "500px", padding: "8px" }}
              value={searchuser}
              onChange={(e) => setsearchuser(e.target.value)}
              type="text"

            />
          </div>
          <div className="gd_explore_main_results_block">
            <div
              style={{ backgroundColor: "white" }}
              className="gd_explore_main_results"
            >
              {fetchedusers.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setselectedUserId(v);
                    setfetchedusers([]);
                  }}
                  className="gd_explore_main_result d-flex justify-content-between align-items-center mb-16 pointer"
                >
                  <div className="d-flex align-items-center">
                    <Avatar
                      src={v.avatar}
                      alt={v.name ?? v.firstname}
                      style={{ height: 40, width: 40 }}
                    />
                    <div style={{ color: "black" }} className="ml-12">
                      <p className="body1">
                        {v.type === "member"
                          ? `${v.firstname} ${v.lastname},(${v.type}) `
                          : `${v.name}, (${v.firstname} ${v.lastname},${v.type})`}

                        <span className="caption"> {v.email}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="plan_main">
        {selectedUserId ? (
          <>
            <p
              className="row align-items-center mx-0 mb-0 pointer"
              style={{
                backgroundColor: "white",
                color: "black",
                padding: "8px",
                width: "50%",
              }}
            >
              <Avatar src={selectedUserId.avatar} />
              &nbsp;
              {`${selectedUserId.firstname} ${selectedUserId.lastname}, (${selectedUserId.name},${selectedUserId.type}) `}
            </p>
            <button
              onClick={() => setselectedUserId(null)}
              style={{ backgroundColor: "red" }}
            >
              X
            </button>
          </>
        ) : (
          ""
        )}
        {history.length > 0 ? (
          <table className="plan_table mt-24">
            <thead className="plan_thead">
              <tr className="plan_thead_tr plan_tr">
                <th className="plan_thead_tr plan_td">Profile</th>
                <th className="plan_thead_tr plan_td">Date</th>
                <th className="plan_thead_tr plan_td">Industry</th>
                <th className="plan_thead_tr plan_td">Country</th>
                <th className="plan_thead_tr plan_td">Employees</th>
                <th className="plan_thead_tr plan_td">Funding</th>
                <th className="plan_thead_tr plan_td">Revenue</th>
                <th className="plan_thead_tr plan_td">Keywords</th>
                <th className="plan_thead_tr plan_td">Founding Date</th>
                <th className="plan_thead_tr plan_td">Results count</th>
              </tr>
            </thead>
            <tbody className="plan_tbody">
              {history.map((plan) => (
                <tr key={plan._id} className="plan_tbody_tr plan_tr">
                  <td className="plan_tbody_td plan_td">
                    <div className="d-flex plan_tbody_logo align-items-center">
                      <img src={plan.userId?.avatar} alt="logo" />
                      <div>
                        <p className="m-0">
                          {plan?.userId?.name
                            ? plan?.userId?.name
                            : `${plan?.userId?.firstname} ${plan?.userId?.lastname}`}
                        </p>
                        <p>
                          {plan?.userId?.firstname} {plan.userId?.lastname}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="plan_tbody_td plan_td">
                    {new Date(plan.createdAt).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  </td>
                  <td className="plan_tbody_td plan_td">
                    <div
                      style={{
                        maxHeight: "100px",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      {plan?.history?.industry &&
                        Array.isArray(plan.history.industry) &&
                        plan?.history?.industry?.map(
                          (v) => getIndustryName(v) + ","
                        )}
                    </div>
                  </td>
                  <td className="plan_tbody_td plan_td">
                    {plan?.history?.location &&
                      Array.isArray(plan.history.location) &&
                      plan?.history?.location?.map((v, i) => (
                        <li key={i}>
                          {`${v.city?.trim() !== "" ? `${v.city}, ` : ""} 
                      ${
                        v.region?.trim() !== "" ? `${v.region}, ` : ""
                      } ${getCountry(v.country)}`}
                        </li>
                      ))}
                  </td>
                  <td className="plan_tbody_td plan_td">
                    {returnEmployees(plan.history.employeeRange)}
                  </td>
                  <td className="plan_tbody_td plan_td">
                    {plan.history.totalFundingRange
                      ? `${ChangeFormat(plan.history.totalFundingRange[0])} -
                        ${ChangeFormat(plan.history.totalFundingRange[1])}`
                      : ""}
                  </td>
                  <td className="plan_tbody_td plan_td">
                    {returnrevenue(plan.history.revenueRange)}
                  </td>
                  <td className="plan_tbody_td plan_td">
                    {plan?.history?.keywords &&
                      Array.isArray(plan.history.keywords) &&
                      plan?.history?.keywords?.map((v) => v + ",")}
                  </td>
                  <td className="plan_tbody_td plan_td">
                    {plan?.history?.foundingDateStart
                      ? returnDate(plan?.history?.foundingDateStart)
                      : ""}
                    -
                    {plan?.history?.foundingDateEnd
                      ? returnDate(plan?.history?.foundingDateEnd)
                      : ""}
                  </td>
                  <td className="plan_tbody_td plan_td">{plan?.totalcount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h2>No History</h2>
        )}
      </div>
      <div className="pagination">
        <Pagination
          count={count ? Math.ceil(count / 20) : 0}
          page={page}
          onChange={(e, v) => setPage(v)}
        />
      </div>
    </div>
  );
};

export default Plans;
