import React from "react";
import Axios from "axios";

import { Avatar, CircularProgress } from "@mui/material";

export default function Activity() {
  const [activity, setactivity] = React.useState(null);

  const fetchStats = async () => {
    const { data } = await Axios({
      method: "get",
      url: "/api/activity/gdstartupinvite",
    });
    setactivity(data.data);
  };
  React.useEffect(() => {
    fetchStats();
  }, []);
  if (activity === "") {
    return <CircularProgress />;
  } else if (activity === null) return <div className="">No Invites</div>;
  else
    return (
      <div className="container admin-user-activity">
        {activity ? (
          <>
            <table>
              <tr>
                <th className="">Sender</th>
                <th className="">GD Startup</th>
                <th className="">Time</th>
              </tr>
              {activity.length > 0 &&
                activity.map((res, i) => (
                  <tr key={i} className="my-2 pt-2">
                    <td className=" my-2 pt-2">
                      <Avatar src={res.userid ? res.userid.avatar : ""} />
                      {res.userid
                        ? `${res.userid.name} (${res.userid.type})`
                        : ""}
                    </td>
                    <td className=" my-2 pt-2">
                      <Avatar
                        src={
                          res.gd_id
                            ? "https://logo.clearbit.com/" + res.gd_id.Website
                            : ""
                        }
                        sx={{ width: 50, height: 50 }}
                        className="no-padding"
                      ></Avatar>
                      {res.gd_id?.CompanyName}
                    </td>
                    <td className=" pt-2">
                      {new Date(res.updatedAt).toLocaleTimeString()}{" "}
                      {new Date(res.updatedAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
            </table>
          </>
        ) : (
          ""
        )}
      </div>
    );
}
