import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  BarChart,
  Bar,
  Rectangle,
  //   ResponsiveContainer
} from "recharts";
import { Avatar } from "@mui/material";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.country} ({value})
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value} ${value > 1 ? "users" : "user"}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
const Graphs = () => {
  const [startDate, setStartDate] = useState(new Date("Sun Jan 01 2022"));
  const [endDate, setEndDate] = useState(new Date());
  const [type, settype] = useState("month");
  const [data, setdata] = useState([]);
  const [searchuser, setsearchuser] = useState("");
  const [fetchedusers, setfetchedusers] = useState([]);
  const [selectedUserId, setselectedUserId] = useState(null);
  const fetchUserByName = async () => {
    try {
      if (searchuser.trim() === "") {
        setselectedUserId(null);
        setfetchedusers([]);
        return;
      }
      const response = await axios.get(`/api/admin/searchuser?s=${searchuser}`);
      if (response.data.success) {
        setfetchedusers(response.data.data);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };

  const getLoginStats = async () => {
    try {
      const response = await axios.get(
        `/api/admin/getactivitygraph?type=${type}&start=${startDate}&end=${endDate}&userid=${selectedUserId?._id}`
      );
      if (response.data.success) {
        setdata(response.data.data);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };
  const [signupStats, setsignupStats] = useState([]);
  const getSignupStats = async () => {
    try {
      const response = await axios.get(
        `/api/admin/getsignupgraph?&start=${startDate}&end=${endDate}`
      );
      if (response.data.success) {
        setsignupStats(response.data.chartdata);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };
  useEffect(() => {
    getLoginStats();
    getSignupStats();
  }, [type, startDate, endDate, selectedUserId]);
  useEffect(() => {
    fetchUserByName();
  }, [searchuser]);
  const [locationdata, setlocationdata] = useState([]);
  const getLocationStats = async () => {
    try {
      const response = await axios.get(`/api/admin/locationstats`);
      if (response.data.success) {
        setlocationdata(response.data.data);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };
  useEffect(() => {
    getLocationStats();
    getPlanStats();
  }, []);
  const [planData, setplanData] = useState([]);

  const getPlanStats = async () => {
    try {
      const response = await axios.get(`/api/admin/planstats`);
      if (response.data.success) {
        setplanData(response.data.data);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };

  const [activeIndex, setactiveIndex] = useState(0);
  return (
    <div className=" font18">
      <div>
        <label>Search User&apos;s Activity</label>{" "}
        <input
          style={{ width: "500px", padding: "8px" }}
          className="auth_input"
          value={searchuser}
          onChange={(e) => setsearchuser(e.target.value)}
          type="text"
          placeholder="Get timeline by name"
        />
        {fetchedusers && fetchedusers.length > 0 && (
          <div
            className="thin_bg "
            style={{
              borderRadius: "5px",
              padding: "5px",
              marginBottom: "15px",
            }}
          >
            {fetchedusers.map((v, i) => {
              return (
                <p
                  className="row align-items-center mx-0 mb-0 pointer"
                  onClick={() => {
                    setselectedUserId(v);
                    setfetchedusers([]);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: "8px",
                  }}
                  key={i}
                >
                  <Avatar
                    src={v.avatar}
                    sx={{ width: 30, height: 30, padding: 0 }}
                  />
                  &nbsp;
                  {`${v.firstname} ${v.lastname}, (${v.name},${v.type}) `}
                </p>
              );
            })}
          </div>
        )}
      </div>
      <div className="">
        <div className="mt-3">
          <p>Select Timeline</p>
          <div className="d-flex w-100 align-items-center">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                sx={{ border: "1px solid #cacaca", borderRadius: "5px" }}
                renderInput={(params) => (
                  <TextField {...params} variant="filled" label="Start Date" />
                )}
              />
            </LocalizationProvider>
            &nbsp; &nbsp; &nbsp;
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                sx={{ border: "1px solid #cacaca", borderRadius: "5px" }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="End Date" />
                )}
              />
            </LocalizationProvider>
            <div className="ms-auto">
              <label className="mb-3" htmlFor="typeDropdown">
                Select range
              </label>{" "}
              <select
                id="typeDropdown"
                value={type}
                onChange={(e) => settype(e.target.value)}
              >
                <option value="week">Weekly</option>
                <option value="month">Monthly</option>
                <option value="year">Yearly</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-12 pt-4">
          <div className="mb-2">
            <div>
              {selectedUserId ? (
                <p> {selectedUserId.firstname}&apos;s activity</p>
              ) : (
                ""
              )}
              {/* <ResponsiveContainer width="100%" height="100%"> */}
              <LineChart
                width={1100}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: -10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={type === "year" ? "year" : "month"} />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: 5,
                  }}
                />
                <Legend />
                <Line
                  // type="monotone"
                  dataKey="logins"
                  stroke="#4ad7d1"
                  strokeWidth="3"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
              {/* </ResponsiveContainer> */}
            </div>
          </div>
        </div>
        <div className="col-12 pt-4">
          <div className="mb-2">
            <div>
              <p>Signup Conversions (Monthly)</p>
              {/* <ResponsiveContainer width="100%" height="100%"> */}
              <LineChart
                width={1100}
                height={300}
                data={signupStats}
                margin={{
                  top: 5,
                  right: 30,
                  left: -10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={"month"} />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: 5,
                  }}
                />
                <Legend />
                <Line
                  // type="monotone"
                  dataKey="signups"
                  stroke="#ffc0cb"
                  strokeWidth="3"
                  activeDot={{ r: 8 }}
                />
                <Line
                  // type="monotone"
                  dataKey="profiles"
                  stroke="#4ad7d1"
                  strokeWidth="3"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
              {/* </ResponsiveContainer> */}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col">
          <h3 className="black"> Location</h3>
          <PieChart width={400} height={400}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={locationdata}
              cx="50%"
              cy="50%"
              innerRadius={75}
              outerRadius={120}
              fill="#4ad7d1"
              dataKey="count"
              onMouseEnter={(_, i) => setactiveIndex(i)}
            />
          </PieChart>
        </div>
        <div className="col">
          <h3 className="black"> Plan</h3>
          <BarChart
            width={500}
            height={300}
            data={planData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="plan" />
            <YAxis />
            <Tooltip contentStyle={{ color: "black" }} />
            <Legend />
            <Bar
              dataKey="users"
              fill="#4ad7d1"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
          </BarChart>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Graphs;
