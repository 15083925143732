import React from "react";
import logo from "../../Assets/Images/Zefyron_logo_white_400W_BETA.png";
import "./navbar.css";
export default function LogoNavbar() {
  return (
    <nav
      className="navbar navbar-expand-lg"
      style={{ backgroundColor: "#161616" }}
    >
      <div className="container-fluid navbar_container">
        <a className="navbar-brand" href="/zr-admin-dashboard">
          <img src={logo} className="navbar_logo" alt="logo" />
        </a>
      </div>
    </nav>
  );
}
