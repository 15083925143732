import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ExcelIcon from "./ExportIcon";
import { toast } from "react-toastify";

export const Export = ({ apiData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    let fieldsToRemove = [
      "isHorvathLead",
      "isRevenueShareWithHorvath",
      "__v",
      "createdAt",
      "updatedAt",
      "_id",
      "logo",
    ];
    const updatedArray = apiData.map((obj) => {
      fieldsToRemove.forEach((field) => delete obj[field]);
      return obj;
    });
    const ws = XLSX.utils.json_to_sheet(updatedArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Sales" + fileExtension);
    toast.success("File downloaded");
  };

  return (
    <button
      className="width_auto export_CTA my-2 ms-auto"
      onClick={() => {
        exportToCSV(apiData);
      }}
    >
      <ExcelIcon />
      Export Excel
    </button>
  );
};
