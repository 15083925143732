import React from "react";

function ExcelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        fill="#185C37"
        d="M14.074 11.032L6.118 9.631v10.356a.857.857 0 00.858.856h13.728a.858.858 0 00.859-.856v-3.815l-7.489-5.14z"
      ></path>
      <path
        fill="#21A366"
        d="M14.074 2.156H6.976a.857.857 0 00-.858.856v3.816l7.956 4.672 4.213 1.402 3.276-1.402V6.828l-7.489-4.672z"
      ></path>
      <path fill="#107C41" d="M6.118 6.828h7.956V11.5H6.118V6.828z"></path>
      <path
        fill="#000"
        d="M11.812 5.894H6.118v11.68h5.694a.862.862 0 00.858-.857V6.75a.862.862 0 00-.858-.856z"
        opacity="0.1"
      ></path>
      <path
        fill="#000"
        d="M11.344 6.361H6.118v11.68h5.226a.862.862 0 00.858-.856V7.217a.862.862 0 00-.858-.856z"
        opacity="0.2"
      ></path>
      <path
        fill="#000"
        d="M11.344 6.361H6.118v10.746h5.226a.862.862 0 00.858-.856V7.217a.862.862 0 00-.858-.856z"
        opacity="0.2"
      ></path>
      <path
        fill="#000"
        d="M10.876 6.361H6.118v10.746h4.758a.862.862 0 00.859-.856V7.217a.863.863 0 00-.859-.856z"
        opacity="0.2"
      ></path>
      <path
        fill="url(#paint0_linear_6650_43509)"
        d="M2.296 6.361h8.58a.857.857 0 01.858.856v8.566a.858.858 0 01-.858.857h-8.58a.859.859 0 01-.858-.857V7.217a.857.857 0 01.858-.856z"
      ></path>
      <path
        fill="#fff"
        d="M4.097 14.283L5.9 11.492 4.248 8.716h1.328l.902 1.777c.083.168.144.293.171.377h.012c.06-.135.122-.265.187-.393l.965-1.758h1.221l-1.695 2.76 1.739 2.806h-1.3l-1.043-1.948a1.695 1.695 0 01-.123-.262h-.017a1.21 1.21 0 01-.12.252L5.4 14.283H4.097z"
      ></path>
      <path
        fill="#33C481"
        d="M20.704 2.156h-6.63v4.672h7.488V3.012a.857.857 0 00-.858-.856z"
      ></path>
      <path fill="#107C41" d="M14.074 11.5h7.488v4.672h-7.488V11.5z"></path>
      <defs>
        <linearGradient
          id="paint0_linear_6650_43509"
          x1="3.23"
          x2="9.942"
          y1="5.689"
          y2="17.312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18884F"></stop>
          <stop offset="0.5" stopColor="#117E43"></stop>
          <stop offset="1" stopColor="#0B6631"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ExcelIcon;
