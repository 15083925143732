import React, { useState } from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    width: "100%",
    margin: "5px 0",
    padding: "10px !important",
    "& > *": {
      margin: "5px !important",
      color: "#4ad7d1 !important",
      border: "1px solid #4ad7d1 !important",
      padding: "7px !important",
    },
  },
}));

const Cards = ({ data }) => {
  const [investor] = useState(data);
  const history = useNavigate();
  const classes = useStyles();
  const handleRedirect = (investor) => {
    // console.log(investor);
    // console.log(history);

    history(`/admin/verify-investor/${investor.id}`, { state: { investor } });
  };
  return (
    <>
      <div className="logo-div">
        <div className="main-logo">
          <figure>
            <img
              src={investor.profilePic}
              alt="aman gupta"
              className="img-fluid"
            />
          </figure>
          <figcaption>
            <p className="founderName"> {investor.name} </p>
            <p className="location">{investor.location.country}</p>
          </figcaption>
        </div>

        <div className={`${classes.root} chips`}>
          {investor.interestedDomains.map((invest, i) => {
            return (
              <Chip
                icon={<AccountBalanceIcon />}
                label={invest}
                clickable
                color="primary"
                variant="outlined"
                key={i}
                className="chipicon"
              />
            );
          })}
        </div>
        <div className="viewBtn">
          <button
            className="gradient_admin_btn"
            onClick={() => handleRedirect(investor)}
          >
            View
          </button>
        </div>
      </div>
    </>
  );
};

Cards.propTypes = {
  data: PropTypes.any.isRequired,
};

export default Cards;
