// import React, { useEffect, useState } from "react";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import CardApi from "../API/FounderApi/CardApi";
import "./index.css";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import DonutLargeRoundedIcon from "@mui/icons-material/DonutLargeRounded";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import ImageIcon from "@mui/icons-material/Image";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Chip from "@mui/material/Chip";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import TimelineIcon from "@mui/icons-material/Timeline";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { makeStyles } from "@mui/styles";
function splitAfterWords(text, wordLimit) {
  const words = text?.split(" "); // Split the text into an array of words
  if (words?.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "..."; // Join the first 50 words and add '...'
  }
  return text; // If the text has less than or equal to 50 words, return as is
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    width: "100%",
    margin: "5px 0",
    padding: "10px !important",
    "& > *": {
      margin: "5px !important",
      color: "#4ad7d1 !important",
      border: "1px solid #4ad7d1 !important",
      padding: "7px !important",
    },
  },
}));

const index = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useNavigate();
  const { data } = location.state;
  const [founder] = useState(data);
  const [verified, setVerify] = useState(false);
  const [decline, setDecline] = useState(false);
  const [document, setdocument] = useState([]);
  const [similargd, setsimilargd] = useState([]);
  const [selectedSimilarGd, setselectedSimilarGd] = useState();
  const [review, setreview] = useState();

  // const { founder } = useParams();
  // const [data, setData] = useState();

  // useEffect(() => {
  //   const newData = CardApi.filter((mydata) => {
  //     return mydata.handle === founder;
  //   });
  //   setData(newData[0]);
  // }, []);

  // verify founder
  const handleVerify = async () => {
    if (!confirm("Are you sure you want to Verify this?")) {
      return;
    }

    founder.selectedSimilarGd = selectedSimilarGd ?? false;
    await Axios.post("/api/admin/handle-startups/accept", founder)
      .then(() => {
        toast.success("Successfully Verified");
        setVerify(true);
        history("/admin/verify-founder");
      })
      .catch(() => {
        toast.error("Some Error");
        setVerify(false);
      });
  };

  const fetchDocument = async () => {
    await Axios({
      method: "GET",
      url: `/api/admin/documents/${founder.id}`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        setdocument(res.data.documents);
      })
      .catch(() => console.log("error"));
  };
  const fetchSimilarGdStartups = async () => {
    await Axios({
      method: "GET",
      url: `/api/admin/checkgdstartups/check/${founder.id}`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        setsimilargd(res.data.data);
      })
      .catch(() => console.log("error"));
  };

  useEffect(() => {
    fetchDocument();
    fetchSimilarGdStartups();
  }, []);

  const sendReview = async () => {
    if (!confirm("Are you sure you want to Send this review?")) {
      return;
    }
    const data = {
      id: founder.id,
      reason: review,
    };
    await Axios.post("/api/admin/review", data)
      .then(() => {
        toast.success("Successfully Sent Review");
      })
      .catch(() => {
        toast.error("some error");
      });
  };
  // decline founder
  const handleDecline = async () => {
    if (!confirm("Are you sure you want to Decline this?")) {
      return;
    }
    await Axios.post("/api/admin/handle-startups/decline", founder)
      .then(() => {
        toast.warning("Successfully Declined");
        setDecline(true);
        setTimeout(() => {
          history("/admin/verify-founder");
        }, 500);
      })
      .catch(() => {
        toast.error("Some Error");
        setDecline(false);
      });
  };
  const date = new Date(founder.incorporationDate);
  if (!founder) {
    return "Empty";
  }

  return (
    <>
      <div className="admin-root container">
        <div className="founder-verify-main">
          <div className="verify-founder-head">
            <div className="verify-founder-head-img">
              <figure>
                <img
                  src={founder.logo}
                  alt={founder.companyName}
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="verify-founder-head-name">
              <p className="verify-founder-company-name">
                {founder.companyName}
              </p>
              <p className="verify-founder-company-loc">
                {founder.location.country}
              </p>
              <p className="verify-founder-company-loc">
                DESCRIPTION : {founder.description}
              </p>
              <p className="verify-founder-company-date">
                {`${date.toDateString()}`}
              </p>
              <a
                href={founder.website}
                target="_blank"
                rel="noreferrer"
                className="verify-founder-company-comweb"
              >
                {founder.website}
              </a>
            </div>
            <div className="verify-founder-head-blank"></div>
          </div>
          <hr className="verify-founder-hr" />
          <div className="verify-founder-info">
            <div className="verify-founder-infomain">
              <AccountBalanceRoundedIcon />
              <p>{founder.fundingSeries}</p>
            </div>
            <div className="verify-founder-infomain">
              <PeopleAltRoundedIcon />
              <p>{founder.employees}</p>
            </div>
            <div className="verify-founder-infomain">
              <TimelineRoundedIcon />
              <p>{founder.growth}</p>
            </div>
            <div className="verify-founder-infomain">
              <EqualizerRoundedIcon />
              <p>{founder.industry} </p>
            </div>
            <div className="verify-founder-infomain">
              <DonutLargeRoundedIcon />
              <p>{founder.audience} </p>
            </div>
          </div>
          <hr className="verify-founder-hr" />
          <div className="w_100">
            <p>Documents</p>
          </div>
          <div className="verify-founder-doc-div">
            <div className="verify-founder-doc">
              {document && document.documents ? (
                document.documents.map((v, i) => {
                  return (
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        key={i}
                        href={v.location}
                        style={{ textDecoration: "none", color: "#fff" }}
                        className="gradient_admin_btn"
                      >
                        View Document
                      </a>
                      <br />
                    </>
                  );
                })
              ) : (
                <p>No Documents found</p>
              )}
              <div className="verify-founder-review">
                <input
                  type="text"
                  className="reviewInput"
                  placeholder="Send review to the Founder"
                  onChange={(e) => setreview(e.target.value)}
                  value={review}
                />
                <div>
                  <button className="black_CTA_view">ADD REVIEW</button>
                  <button
                    onClick={() => sendReview()}
                    className="gradient_admin_btn"
                  >
                    SEND ALL REVIEW
                  </button>
                </div>
              </div>
            </div>
            <div className="verify-founder-profile">
              <div className="verify-founder-profile-img">
                <figure>
                  <img
                    src={founder.profilePic}
                    alt={founder.name}
                    className="img-fluid"
                  />
                </figure>
                <figcaption>{founder.name}</figcaption>
              </div>
              <div className="verify-founder-profile-allinfo">
                <div className="allinfo-details-item">
                  <WorkRoundedIcon />
                  <p>{founder.industry}</p>
                </div>
                <div className="allinfo-details-item">
                  <PhoneIcon />
                  <p>{founder.contact.phone}</p>
                </div>
                <div className="allinfo-details-item">
                  <AlternateEmailRoundedIcon />
                  <p>{founder.contact.email}</p>
                </div>
                <div className="allinfo-details-item">
                  <LinkedInIcon />
                  <a
                    href={data.socialConnects.linkedin}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Linkedin
                  </a>
                </div>
                <div className="allinfo-details-item">
                  <ImageIcon />
                  {document ? (
                    <a href={document.photoid} target="_blank" rel="noreferrer">
                      Photo Id
                    </a>
                  ) : (
                    "No Photo Id Found"
                  )}
                </div>
              </div>
              <div className="verifybtn">
                {decline ? (
                  <button className="black_CTA_view">DECLINED</button>
                ) : (
                  <button
                    className="black_CTA_view"
                    onClick={() => handleDecline()}
                  >
                    DECLINE
                  </button>
                )}

                {verified ? (
                  <button className="black_CTA_view">
                    <DoneAllIcon />
                    VERIFIED
                  </button>
                ) : (
                  <button
                    className="black_CTA_view"
                    onClick={() => handleVerify()}
                  >
                    <DoneAllIcon />
                    VERIFY
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid-container mx-5 ">
        <h1>Global database with similar names</h1>
        (Selecting any below startup will update this old GD startup details
        else Fresh GD startup will be added)
        <div className="row">
          {similargd.map((data, i) => (
            <div
              style={
                data._id === selectedSimilarGd
                  ? { backgroundColor: "skyblue" }
                  : { backgroundColor: "white" }
              }
              key={i}
              className="logo-div pointer"
              onClick={() => {
                if (data._id === selectedSimilarGd) {
                  setselectedSimilarGd(null);
                } else setselectedSimilarGd(data._id);
              }}
            >
              <div className="main-logo">
                <figure>
                  <img
                    src={"https://logo.clearbit.com/" + data.Website}
                    alt="bookify"
                    className="img-fluid"
                  />
                </figure>
                <figcaption>
                  <p className="companyName">{data.CompanyName}</p>
                  <p className="founderName">
                    {String(data.FullDescription).trim() != ""
                      ? splitAfterWords(data.FullDescription, 100)
                      : splitAfterWords(data.ShortDescription, 100)}
                  </p>
                  <p className="location">{`${data.City}, ${data.Region}, ${data.Country}`}</p>
                </figcaption>
              </div>

              <div className={`${classes.root} chips`}>
                <Chip
                  icon={<AccountBalanceIcon />}
                  label={data.FundingStatus}
                  clickable
                  color="primary"
                  variant="outlined"
                  className="chipicon"
                />
                <Chip
                  icon={<SupervisorAccountIcon />}
                  label={data.NoOfEmployees}
                  clickable
                  color="primary"
                  variant="outlined"
                  className="chipicon"
                />
                <Chip
                  icon={<TimelineIcon />}
                  label={data.EstimatedRevenueRange}
                  clickable
                  color="primary"
                  variant="outlined"
                  className="chipicon"
                />

                <Chip
                  icon={<EqualizerIcon />}
                  label={data.Industry}
                  clickable
                  color="primary"
                  variant="outlined"
                  className="chipicon"
                />
                {/* <Chip
           icon={<DonutLargeIcon />}
           label={data.audience}
           clickable
           color="primary"
           variant="outlined"
           className="chipicon"
         /> */}
              </div>

              <div className="viewBtn">
                <button
                  className="gradient_admin_btn"
                  onClick={() => window.open(data.Website)}
                >
                  View
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default index;
