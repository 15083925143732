import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import { encryptPreHashed } from "../../../../Utils/encyption";
import Axios from "axios";

import { toast } from "react-toastify";
import { useAuth } from "../../../../Utils/Auth";
import { CircularProgress } from "@mui/material";
import UpIntradayIcon from "../../../../Assets/Icons/UptrendIcon";
import localNumber from "../../../../Utils/localnumber";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";

export default function Revenues() {
  const {
    setcallSuperAdmin,
    callSuperAdmin,
    superAdmin,
    // setsuperAdmin
  } = useAuth();
  const [password, setPassword] = useState("");
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [disable, setdisable] = useState(false);
  const [otpStatus, setotpStatus] = useState(null);
  const [code, setCode] = useState(null);
  const [isLoader, setisLoader] = useState(false);
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    // if (isNewDevice || otpStatus === "sent") {
    //   verifyOtp();
    //   return;
    // }

    setdisable(true);
    const data = {
      password: encryptPreHashed(password),
    };
    console.log(encryptPreHashed(password));

    try {
      let res = await Axios.post(`http://localhost:5002/api/admin/login`, data);
      if (res.data.success === true) {
        setotpStatus("sent");
        toast.success("Enter Security PIN");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const verifyOtp = async () => {
    if (!code) {
      toast.error("Please Enter Valid OTP", { position: "bottom-right" });
    } else {
      setisLoader(true);
      Axios({
        method: "POST",
        url: `http://localhost:5002/api/admin/login-otp-verify`,
        data: {
          code,
        },
      })
        .then((response) => {
          if (response.data.status === "approved") {
            setcallSuperAdmin(!callSuperAdmin);

            toast.success("Access Granted", { position: "bottom-right" });
          } else if (response.data.status === "pending") {
            toast.error("Invalid OTP", { position: "bottom-right" });
            setisLoader(false);
          } else {
            toast.error("Something went wrong", { position: "bottom-right" });
            setisLoader(false);
          }
        })
        .catch((error) => {
          console.log(error.message);
          toast.error("Something went wrong", { position: "bottom-right" });
        });
    }
  };

  const handleotpchange = (check6digit) => {
    setCode(check6digit.slice(0, 6));
  };
  React.useEffect(() => {
    if (code && code.length === 6) {
      verifyOtp();
    }
  }, [code]);

  const data = [
    {
      name: "Jan",
      2024: 4000,
      2023: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      2024: 3000,
      2023: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      2024: 2000,
      2023: 9800,
      amt: 2290,
    },
    {
      name: "April",
      2024: 2780,
      2023: 3908,
      amt: 2000,
    },
    {
      name: "May",
      2024: 1890,
      2023: 4800,
      amt: 2181,
    },
    {
      name: "June",
      2024: 2390,
      2023: 3800,
      amt: 2500,
    },
    {
      name: "July",
      2024: 3490,
      2023: 4300,
      amt: 2100,
    },
    {
      name: "Aug",
      2024: 2780,
      2023: 3908,
      amt: 2000,
    },
    {
      name: "Sep",
      2024: 1890,
      2023: 4800,
      amt: 2181,
    },
    {
      name: "Oct",
      2024: 2390,
      2023: 3800,
      amt: 2500,
    },
    {
      name: "Nov",
      2024: 3490,
      2023: 4300,
      amt: 2100,
    },
    {
      name: "Dec",
      2024: 1890,
      2023: 4800,
      amt: 2181,
    },
  ];

  const data2 = [
    {
      name: "Jan",
      2024: 40,
      2023: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      2024: 30,
      2023: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      2024: 202,
      2023: 9800,
      amt: 2290,
    },
    {
      name: "April",
      2024: 270,
      2023: 3908,
      amt: 2000,
    },
    {
      name: "May",
      2024: 189,
      2023: 4800,
      amt: 2181,
    },
    {
      name: "June",
      2024: 230,
      2023: 3800,
      amt: 2500,
    },
    {
      name: "July",
      2024: 390,
      2023: 4300,
      amt: 2100,
    },
    {
      name: "Aug",
      2024: 80,
      2023: 3908,
      amt: 2000,
    },
    {
      name: "Sep",
      2024: 102,
      2023: 4800,
      amt: 2181,
    },
    {
      name: "Oct",
      2024: 202,
      2023: 3800,
      amt: 2500,
    },
    {
      name: "Nov",
      2024: 340,
      2023: 4300,
      amt: 2100,
    },
    {
      name: "Dec",
      2024: 189,
      2023: 4800,
      amt: 2181,
    },
  ];

  return (
    <div>
      {superAdmin ? (
        <div className="text_align_center mt-5">
          <h4>Enter Security Password</h4>
          <label className="auth_label w-40">
            <input
              className="auth_input auth_input_password"
              placeholder="Enter your password"
              required
              type={!values.showPassword ? "password" : "text"}
              value={password}
              disable={disable}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              className="auth_input_pas_btn"
              type="button"
              onClick={() => handleClickShowPassword()}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </button>
          </label>
          {otpStatus === "sent" ? (
            <>
              <br />
              <input
                type="number"
                className="auth_input w-40 black"
                placeholder="OTP"
                value={code}
                onChange={(e) => handleotpchange(e.target.value)}
              />{" "}
            </>
          ) : null}
          <br />
          {otpStatus === "sent" ? (
            <button
              className="gradient_admin_btn mt-16 px-3"
              onClick={(e) => handleOnSubmit(e)}
            >
              SUBMIT CODE
            </button>
          ) : (
            <button
              disabled={isLoader}
              className="gradient_admin_btn mt-16 px-3"
              onClick={(e) => handleOnSubmit(e)}
            >
              SUBMIT
            </button>
          )}

          {isLoader ? (
            <div className="mt-5">
              <CircularProgress />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="">
          <div>
            <div className="d-flex justify_btwn pb-4">
              <h4>Revenues (Dummy)</h4>
              <select className="me-3 w-120px">
                <option>2024</option>
                <option>2023</option>
                <option>2022</option>
                <option>All</option>
              </select>
            </div>
            <div className="row ms-0 me-0">
              <div className="col-3 ps-0">
                <div className="admin_box">
                  <div className="d-flex justify_btwn">
                    <p className="p_nunito_18_black">Total Revenue</p>
                    <p className="p_nunito_18_black">2024</p>
                  </div>
                  <h4 className="h2_black_40">€ {localNumber(94322)}</h4>
                  <p className="green mb-0">
                    <UpIntradayIcon cc={"#00915B"} />
                    20%
                  </p>
                </div>
              </div>
              <div className="col-2">
                <div className="admin_box">
                  <p className="p_nunito_18_black">Total Customers</p>
                  <h4 className="h2_black_40">604</h4>
                  <p className="green mb-0">
                    <UpIntradayIcon cc={"#00915B"} />
                    15%
                  </p>
                </div>
              </div>
              <div className="col-2">
                <div className="admin_box">
                  <p className="p_nunito_18_black">Subscriptions</p>
                  <h4 className="h2_black_40">25</h4>
                  <p className="green mb-0">
                    <UpIntradayIcon cc={"#00915B"} />
                    15%
                  </p>
                </div>
              </div>
              <div className="col-2">
                <div className="admin_box">
                  <p className="p_nunito_18_black">Average Order Value</p>
                  <h4 className="h2_black_40">€ {localNumber(1534)}</h4>
                  <p className="green mb-0">
                    <UpIntradayIcon cc={"#00915B"} />
                    15%
                  </p>
                </div>
              </div>

              <div className="col-3">
                <div className="admin_box">
                  <p className="p_nunito_18_black">Top Paying Customers</p>
                  <div className="d-flex ms-0 me-0 justify_btwn mt-4">
                    <p className=" mb-0">Today Green</p>
                    <span className="mb-0">15K</span>
                  </div>
                  <div className="d-flex ms-0 me-0 justify_btwn mt-4">
                    <p className=" mb-0">Mercedes</p>
                    <span className="mb-0">10K</span>
                  </div>

                  <div className="d-flex ms-0 me-0 justify_btwn mt-4">
                    <p className=" mb-0">CATL</p>
                    <span className="mb-0">8K</span>
                  </div>
                  <div className="d-flex ms-0 me-0 justify_btwn mt-4">
                    <p className=" mb-0">WVIB</p>
                    <span className="mb-0">7K</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 d-flex">
              <div className="col-6">
                <h3>Revenues</h3>
                <ResponsiveContainer height={400}>
                  <BarChart
                    // width={700}
                    // height={400}
                    data={data}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      stroke="#82ca9d"
                    />
                    <Tooltip />
                    <Legend />
                    <Bar yAxisId="left" dataKey="2024" fill="#8884d8" />
                    <Bar yAxisId="right" dataKey="2023" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>

              <div className="col-6">
                <h3>MRR</h3>
                <ResponsiveContainer height={400}>
                  <LineChart
                    // width={700}
                    // height={400}
                    data={data2}
                    margin={{
                      top: 5,
                      right: 30,
                      left: -10,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={"name"} />
                    <YAxis />
                    <Tooltip
                      contentStyle={{
                        color: "black",
                        backgroundColor: "white",
                        borderRadius: 5,
                      }}
                    />
                    <Legend />
                    <Line
                      // type="monotone"
                      dataKey="2024"
                      stroke="#8884d8"
                      strokeWidth="3"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* <h6>Total Revenue</h6>
            <p>EUR 4.233.342,80 </p>
            <h6>Revenue Chart</h6> */}
          </div>
          {/* <div>MRR</div>
          <div>No. of Customers</div>
          <div>Invoices</div>
          <div>Quotations</div>
          <div>Revenues</div> */}
        </div>
      )}
    </div>
  );
}
